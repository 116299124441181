import React, { useEffect, useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { doesUserMatchSearch, PAGINATION_DIRECTION } from 'users/containers/Organization/consts';
import AddUsersToRoleModalTable from './AddUsersToRoleModalTable';
import AddUsersToRoleModalHeader from './AddUsersToRoleModalHeader';

import styles from './AddUsersToRoleModal.module.scss';

const AddUsersToRoleModal = ({ isOpen, setIsOpen, role }) => {
  const [saveClicked] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);

  const { NewTableWrapper } = useTable();

  const { fetchUsers, addRolesToUsers } = useUsers(!isOpen);
  const { data, isLoading } = fetchUsers({ paginationToken, search });

  useEffect(() => {
    if (data?.users && !isLoading) {
      const usersWithoutTheRole = data?.users.filter((user) => user.roles.every((r) => r.id !== role.identifier?.id));
      const searchedUsers = search
        ? usersWithoutTheRole.filter((user) => doesUserMatchSearch(user, search))
        : usersWithoutTheRole;
      setDisplayedUsers(searchedUsers);
    }
  }, [data, isLoading, role, isOpen, search]);

  useEffect(() => {
    setSelectedUserIds([]);
  }, [data?.users]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    await addRolesToUsers.mutateAsync({
      userIds: selectedUserIds,
      roles: [role.identifier],
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="add-users-to-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title="Add Users"
    >
      <>
        {isLoading ? (
          <Spinner />
        ) : (
          <NewTableWrapper className="sub-table" isCompact>
            <AddUsersToRoleModalHeader
              selectedUsersCount={displayedUsers?.length}
              search={search}
              setSearch={setSearch}
              pageChanged={pageChanged}
              total={data?.total}
            />
            <AddUsersToRoleModalTable users={displayedUsers} setSelectedUserIds={setSelectedUserIds} />
          </NewTableWrapper>
        )}
      </>
    </CustomModal>
  );
};

export default AddUsersToRoleModal;
