import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import {
  FilterTypes,
  mapSpAnalyzerConstValueToDisplayValue,
  SpObligatoryFilters,
} from 'usage/constants/usageConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FieldFilter from 'shared/components/FieldFilter';
import { propComparator } from 'shared/utils/sortUtil';
import styles from './FilterBa.module.scss';

class ImmediateFilterBar extends PureComponent {
  static propTypes = {
    selectedOptionsMap: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
    filterStatusTypeMap: PropTypes.object.isRequired,
    handleChangeFilterType: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getFilterLabel = (field) => {
    let label = awsCommonFieldToDisplayField.get(field);
    if (SpObligatoryFilters.includes(field)) {
      label += ' *';
    }

    return label;
  };

  // createOptions = fieldValues => fieldValues.map(fieldValue => ({ value: fieldValue, label: fieldValue }));
  createOptions = (fieldValues) =>
    !fieldValues
      ? [{ value: '', label: '' }]
      : fieldValues.map((fieldValue) => {
          let value = '';
          let label = '';
          if (Object.prototype.toString.call(fieldValue) === '[object Object]' && 'linkedAccountId' in fieldValue) {
            value = fieldValue.linkedAccountId;
            label = fieldValue.displayLabel;
          } else {
            value = fieldValue;
            label = fieldValue;
          }
          return { value, label };
        });

  tooltipActionDescription = (status) =>
    status === FilterTypes.INCLUDE
      ? mapSpAnalyzerConstValueToDisplayValue.get(FilterTypes.EXCLUDE)
      : mapSpAnalyzerConstValueToDisplayValue.get(FilterTypes.INCLUDE);

  renderFilters = (fieldToFieldDistincValuesMap, selectedOptionsMap) => {
    const filters = [];
    if (!fieldToFieldDistincValuesMap) {
      return null;
    }
    if (fieldToFieldDistincValuesMap.size > 0) {
      fieldToFieldDistincValuesMap.forEach((fieldValues, field) => {
        const options = this.createOptions(fieldValues);
        if (AwsCommonFields.QUANTITY_TYPE !== field) {
          filters.push(
            <div style={{ marginLeft: '0px' }} className="filter-bar-inner-col-wrapper full-width">
              <div style={{ display: 'flex' }}>
                <Tooltip
                  arrow
                  title={`Press here to ${this.tooltipActionDescription(
                    this.props.filterStatusTypeMap.get(field),
                  ).toLowerCase()} the selected values`}
                  placement="top"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<AddBoxIcon fontSize="small" />}
                        checkedIcon={<IndeterminateCheckBoxIcon fontSize="small" />}
                        checked={this.props.filterStatusTypeMap.get(field)}
                        onChange={() => this.props.handleChangeFilterType(field)}
                        classes={{
                          root: styles.root,
                        }}
                      />
                    }
                  />
                </Tooltip>
                <h5 className="filter-bar-inner-col-title" style={{ marginTop: '15px', marginLeft: '-13px' }}>
                  {this.getFilterLabel(field)}
                </h5>
              </div>
              <FieldFilter
                type={field}
                value={selectedOptionsMap.get(field)}
                options={options ? options.sort(propComparator('label')) : options}
                handleChange={this.props.handleFilterChange}
              />
            </div>,
          );
        }
      });
    }

    return filters;
  };

  render() {
    const { fieldToFieldDistincValuesMap, selectedOptionsMap } = this.props;
    return <>{this.renderFilters(fieldToFieldDistincValuesMap, selectedOptionsMap)}</>;
  }
}

export default ImmediateFilterBar;
