/* eslint-disable max-len,arrow-parens */
export const MINIMUM_PASSWORD_LENGTH = 8;
export const USERNAME = 8;
export const USERNAME_VALIDATION_RESULT_STRINGS = {
  INVALID_USERNAME: '- Username should be in the form of an email',
};

export const INVALID_CONFIRM_STRING = 'There was an error confirming your account please check your confirmation code';
export const INVALID_RESULT_STRING = 'There was an error - Please try again';

export const PASSWORD_VALIDATION_RESULT_STRINGS = {
  INVALID_LENGTH: '- Password should be more than 8 characters',
  INVALID_NO_SPECIAL_CHAR: '- Password should include at least 1 special character',
  INVALID_NO_UPPERCASE_CHAR: '- Password should include at least 1 upper-case character',
  INVALID_NO_LOWERCASE_CHAR: '- Password should include at least 1 lower-case character',
  INVALID_NO_NUMBER: '- Password should include at least 1 number character',
};

export class UsersType {
  static SUPER_USER = 999;
  static USER = 1;
  static NEW_USER = 2;
  static UN_AUTH_USER = 3;
  static BETA_USER = 4;
  static USER_ON_BOARDED = 5; // user who finished the connection process but first invoice is not loaded to Pileus yet
  static USER_END_TRIAL = 6; // user who ended his trial time
  static SUB_USER = 7;
  static RESELLER = 9;
  static RESELLER_CUSTOMER = 10;
  static RESELLER_CUSTOMER_EDP = 11;
  static BUSINESS_ADMIN = 12;
  static RESELLER_BUSINESS_ADMIN = 13;
}

export class UsersAccountTypeId {
  static REGULAR = 1;
  static RESELLER = 2;
  static MULTI_ACCOUNT = 3;
  static RESELLER_MULTI_ACCOUNT = 4;
  static MULTI_CLOUDS_ACCOUNT = 6;
  static RESELLER_MULTI_CLOUDS_ACCOUNT = 5;
  static M3C_ACCOUNT = 7;
}

export const isUsersAccountTypeTenant = (typeId) =>
  [
    UsersAccountTypeId.MULTI_ACCOUNT,
    UsersAccountTypeId.RESELLER_MULTI_ACCOUNT,
    UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT,
    UsersAccountTypeId.MULTI_CLOUDS_ACCOUNT,
    UsersAccountTypeId.M3C_ACCOUNT,
  ].includes(typeId);

export const PreferencesTypes = {
  RECOMMENDATION: 'recommendation',
  METRICS: 'metrics',
  RESELLER: 'reseller',
  K8S: 'k8s',
};

export const PreferencesKeys = {
  METRICS_FILTERS: 'metrics-filters',
  CUSTOMER_INVOICE: 'customer-invoice',
  REBILLING_RULES: 'rebilling-rules',
  POD_COST_CALCULATION: 'pod-cost-calculation',
};

export class RoleUsersType {
  static ADMIN = 0;
  static EDITOR = 1;
  static VIEWER = 2;
}

export const mapRoleUserTypeToDisplay = new Map([
  [RoleUsersType.ADMIN, 'Admin'],
  [RoleUsersType.EDITOR, 'Editor'],
  [RoleUsersType.VIEWER, 'Viewer'],
]);

export const TABS_IDS = {
  COST_CENTER: 3,
  CLOUD_ACCOUNTS: 4,
  USERS: 5,
  LINKED_ACCOUNTS: 6,
  SUBSCRIPTIONS: 7,
  AZURE_APP_REG: 8,
  TARGET_GOALS: 11,
  SETTINGS: 12,
  CHANNELS: 14,
  ROLES: 15,
};

export const userTypeToAccountTabsMap = new Map([
  [
    UsersType.SUPER_USER,
    [TABS_IDS.COST_CENTER, TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.USERS, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS],
  ],
  [
    UsersType.USER,
    [TABS_IDS.COST_CENTER, TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.USERS, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS],
  ],
  [UsersType.NEW_USER, [TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.SETTINGS]],
  [UsersType.UN_AUTH_USER, []],
  [UsersType.BETA_USER, [TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.USERS, TABS_IDS.SETTINGS]], // changing Beta to reseller
  [UsersType.USER_ON_BOARDED, [TABS_IDS.COST_CENTER, TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.SETTINGS]],
  [UsersType.USER_END_TRIAL, []],
  [UsersType.SUB_USER, [TABS_IDS.COST_CENTER, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
  [UsersType.READ_ONLY_USER, [TABS_IDS.COST_CENTER, TABS_IDS.CLOUD_ACCOUNTS]],
  [UsersType.RESELLER, [TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.USERS, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
  [UsersType.RESELLER_CUSTOMER, [TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
  [UsersType.RESELLER_CUSTOMER_EDP, [TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
  [UsersType.BUSINESS_ADMIN, [TABS_IDS.COST_CENTER, TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
  [UsersType.RESELLER_BUSINESS_ADMIN, [TABS_IDS.CLOUD_ACCOUNTS, TABS_IDS.SETTINGS, TABS_IDS.CHANNELS]],
]);

export const DIVISION_TYPE_ID = {
  USER: 0,
  CUSTOMER: 1,
  CUSTOMER_WITH_USERS: 2,
  COST_CENTER: 3,
  COST_CENTER_WITH_USERS: 4,
};

export const CLOUD_TYPE_IDS = {
  AWS: 0,
  AZURE: 1,
  GCP: 2,
  MULTI: 4,
};

export const USER_TYPES_VALS = {
  SUPER_USER: 999,
  USER: 1,
  NEW_USER: 2,
  UN_AUTH_USER: 3,
  BETA_USER: 4,
  USER_ON_BOARDED: 5,
  USER_END_TRIAL: 6,
  SUB_USER: 7,
  RESELLER: 9,
  RESELLER_CUSTOMER: 10,
  RESELLER_CUSTOMER_EDP: 11,
  BUSINESS_ADMIN: 12,
  RESELLER_BUSINESS_ADMIN: 13,
};

export const cloudTypeToAccountTabsMap = new Map([
  [CLOUD_TYPE_IDS.AWS, [TABS_IDS.LINKED_ACCOUNTS]],
  [CLOUD_TYPE_IDS.AZURE, [TABS_IDS.SUBSCRIPTIONS, TABS_IDS.AZURE_APP_REG]],
  [CLOUD_TYPE_IDS.GCP, [TABS_IDS.SUBSCRIPTIONS]],
]);

export const customerCloudTypeToAccountTabsMap = new Map([
  [CLOUD_TYPE_IDS.AWS, [TABS_IDS.LINKED_ACCOUNTS]],
  [CLOUD_TYPE_IDS.AZURE, [TABS_IDS.SUBSCRIPTIONS]],
  [CLOUD_TYPE_IDS.GCP, [TABS_IDS.SUBSCRIPTIONS]],
]);

export const edpCustomerCloudTypeToAccountTabsMap = new Map([
  [CLOUD_TYPE_IDS.AWS, [TABS_IDS.LINKED_ACCOUNTS]],
  [CLOUD_TYPE_IDS.AZURE, [TABS_IDS.AZURE_APP_REG, TABS_IDS.SUBSCRIPTIONS]],
  [CLOUD_TYPE_IDS.GCP, [TABS_IDS.SUBSCRIPTIONS]],
]);

export const mapCloudTypeIdToDescription = new Map([
  [CLOUD_TYPE_IDS.AWS, 'AWS'],
  [CLOUD_TYPE_IDS.AZURE, 'AZURE'],
  [CLOUD_TYPE_IDS.GCP, 'GCP'],
  [CLOUD_TYPE_IDS.MULTI, 'MULTICLOUD'],
]);

export const mapCloudDescriptionToTypeId = new Map([
  ['AWS', CLOUD_TYPE_IDS.AWS],
  ['AZURE', CLOUD_TYPE_IDS.AZURE],
  ['GCP', CLOUD_TYPE_IDS.GCP],
  ['MULTICLOUD', CLOUD_TYPE_IDS.MULTI],
]);

export const mapParentUserTypeToDivisionUserTypeAndId = new Map([
  [UsersType.USER, { userType: UsersType.SUB_USER, divisionTypeId: DIVISION_TYPE_ID.COST_CENTER }],
  [UsersType.SUPER_USER, { userType: UsersType.SUB_USER, divisionTypeId: DIVISION_TYPE_ID.COST_CENTER }],
  [UsersType.SUB_USER, { userType: UsersType.SUB_USER, divisionTypeId: DIVISION_TYPE_ID.COST_CENTER }],
  [UsersType.USER_ON_BOARDED, { userType: UsersType.SUB_USER, divisionTypeId: DIVISION_TYPE_ID.COST_CENTER }],
  [UsersType.RESELLER, { userType: UsersType.RESELLER_CUSTOMER, divisionTypeId: DIVISION_TYPE_ID.CUSTOMER }],
  [UsersType.RESELLER_CUSTOMER, { userType: UsersType.RESELLER_CUSTOMER, divisionTypeId: DIVISION_TYPE_ID.CUSTOMER }],
  [
    UsersType.RESELLER_CUSTOMER_EDP,
    {
      userType: UsersType.RESELLER_CUSTOMER,
      divisionTypeId: DIVISION_TYPE_ID.CUSTOMER,
    },
  ],
  [UsersType.BETA_USER, { userType: UsersType.RESELLER_CUSTOMER, divisionTypeId: DIVISION_TYPE_ID.CUSTOMER }],
]);

// ALERTS TABLE

export const ALERTS_TABLE_COLUMNS = [
  // { name: 'id', title: 'Id', getCellValue: (row) => row.id },
  { name: 'type', title: 'Type', getCellValue: (row) => row.type },
  { name: 'linkedAccountId', title: 'Linked Acc Id', getCellValue: (row) => row.linkedAccountId },
  {
    name: 'date',
    title: 'Date',
    getCellValue: (row) => row.date,
  },
  {
    name: 'description',
    title: 'Description',
    getCellValue: (row) => row.description,
  },
  {
    name: 'userAction',
    title: 'Dismiss alert',
    getCellValue: (row) => row.description,
  },
];
export const ALERTS_COLUMN_WIDTHS = [
  // { columnName: 'id', width: 200 },
  { columnName: 'type', width: 200 },
  { columnName: 'linkedAccountId', width: 200 },
  { columnName: 'description', width: 400 },
  { columnName: 'date', width: 200 },
  { columnName: 'userAction', width: 200 },
];

export const ALERTS_TABLE_COLUMN_EXTENSIONS = [{ columnName: 'id', align: 'center' }];
export const ALERTS_DEFAULT_HIDDEN_COLUMN_NAMES = ['id'];
export const ALERTS_DEFAULT_SORTING = [{ columnName: 'date', direction: 'desc' }];

export const mapTableFieldToDisplay = new Map([
  ['awsAccountId', 'Account Id'],
  ['accountId', 'Account Id'],
  ['linkedAccountId', 'Linked Account Id'],
]);

export const ACCOUNT_FEATURES = {
  K8S: 'k8s',
  METRICS_EXPLORER: 'metrics-explorer',
  ROLES: 'roles',
  MONDAY: 'monday',
  EXCLUSIONS: 'exclusions',
  DISABLE_CLARITY_BIZPACK: 'disableClarityBizpack',
  CUSTOMER_NEW_FLOW: 'customer_new_flow',
  ALERTS: 'alerts-new',
  ALERTS_STATIC: 'alerts-new-static-type',
  RECOMMENDATIONS: 'recommendations-new',
  RECOMMENDATIONS_PREFERENCES: 'preferences-new',
  CUE_K8S_FILTERS: 'cue-k8s-filters',
  AZURE_EA_MCA_ENABLE_MIGRATION: 'ea-mca-enable-migration',
  ENABLE_MULTI_CURRENCY: 'enable-multi-currency',
  AZURE_SERVICE_PLAN_EXPLORER: 'azure-service-plan-explorer',
  GCP_CUE_HOURLY_GRAN: 'gcp-cue-hourly-gran',
  AWS_CUE_HOURLY_GRAN: 'aws-cue-hourly-gran',
  CUSTOMER_TRIGGER_REBILLING: 'customer-trigger-rebilling-ui',
  SELF_MANAGED_CUSTOMERS: 'self-managed-customers',
  SP_ANALYZER_NEW: 'sp_analyzer2_enabled',
  BILLING_RULES_NEW: 'billing-rules-new',
  RECOMMENDATION_VIEWS: 'recommendation-views',
  BILLING_SUMMARY: 'billing-summary',
  PERMISSION_TOOLTIP_DEBUG: 'permission-tooltip-debug',
};

export const USER_ACTIONS = {
  ADD_ACCOUNT: 'addaccount',
};

export const mapUserActionsToAuthorizedUsers = new Map([
  [USER_ACTIONS.ADD_ACCOUNT, [UsersType.USER, UsersType.RESELLER]],
]);

export const shareSettingsItemTypes = {
  CUSTOM_DASHBOARD: 'CUSTOM_DASHBOARD',
};
