import { API } from 'shared/utils/apiMiddleware';
import { isNil } from 'lodash';
import { BILLINGS_API_NAME, USER_MANAGEMENT_ROLES } from './helperFunctions';

export async function fetchRoles(params) {
  const queryString = params
    ? Object.keys(params)
        ?.filter((key) => !isNil(params[key]))
        ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        ?.join('&')
    : null;
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}${queryString ? `?${queryString}` : ''}`);
}

export async function createRole(role) {
  const payload = {
    body: { role },
  };
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}`, payload);
}

export async function deleteRoles(roleInternalNames) {
  const payload = {
    body: { roleInternalNames },
  };
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}`, payload);
}

export async function fetchRoleByInternalName(roleInternalName) {
  const encodedName = encodeURIComponent(roleInternalName);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}`);
}

export async function fetchRoleDataAccess(roleInternalName) {
  const encodedName = encodeURIComponent(roleInternalName);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/data-access`);
}

export async function setRoleDataAccess(roleInternalName, roleDataAccess) {
  const payload = {
    body: { roleDataAccess },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/data-access`, payload);
}

export async function fetchRolePermissions(roleInternalName) {
  const encodedName = encodeURIComponent(roleInternalName);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions`);
}

export async function setRolePermissions(roleInternalName, roleCategoryPermissions) {
  const payload = {
    body: { roleCategoryPermissions },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions`, payload);
}

export async function fetchPartialRolePermissions(roleInternalName, category, actionId) {
  const encodedName = encodeURIComponent(roleInternalName);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${actionId}`);
}

export async function addPartialRolePermissions(roleInternalName, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.put(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${action}`,
    payload,
  );
}

export async function deletePartialRolePermissions(roleInternalName, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.del(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${action}`,
    payload,
  );
}

export async function addSubRolesToRole(roleInternalName, subRolesInternalNames) {
  const payload = {
    body: { subRolesInternalNames },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/sub-roles`, payload);
}

export async function removeSubRolesFromRole(roleInternalName, subRolesInternalNames) {
  const payload = {
    body: { subRolesInternalNames },
  };
  const encodedName = encodeURIComponent(roleInternalName);
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/sub-roles`, payload);
}

export async function fetchMergedPermissionsOfRoles(roleInternalNames) {
  const payload = {
    body: { roleInternalNames },
  };
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/get-merged-permissions`, payload);
}
