import React, { useContext, useMemo } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import {
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  CLOSED_AND_DONE_RECS_DATES_OPERATOR,
  DEFAULT_RECS_DATE_RANGE,
  DROPDOWN_ADDITIONAL_FILTERS,
  DROPDOWN_MAIN_FILTERS,
  FILTERS,
  STATUS_FILTER_PRESETS,
  SWITCH_BUTTON_STATUSES,
  tooltipTextForCustomStatusFilter,
  USER_STATUS_FILTERS,
} from 'recommendationsNew/consts';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { isEqual } from 'lodash';
import moment from 'moment';
import { ServicesImageTag } from '../servicesFilter/selectMultiMenuItem/ServicesImageTag';

import classes from './heatMapSelectedFiltersDisplay.module.scss';
import HeatMapActionsContext from '../../contexts/heatMapActionsContext';

const SelectedFilterDisplay = ({ selectedFilter, removeSelectedFilter, isOpen, setIsOpen }) => {
  const {
    recommendationFilters: filtersContext,
    isDashboardPanel,
    showOpenOnly,
    showDoneCompletedOnly,
  } = useRecommendationsNewContext();
  const { isStatusFilterOpen, setIsStatusFilterOpen } = useContext(HeatMapActionsContext);

  const selectedFilterValue = filtersContext[selectedFilter];

  const getSelectedServiceFilterMenuOption = (optionLabel, optionClass) => (
    <div
      className={classes.serviceFilterNameContainer}
      automation-id={`heatmap-selected-service-filter-menu-option-${optionLabel}`}
    >
      <div className={classes.serviceFilterImage}>
        <ServicesImageTag label={optionLabel} />
      </div>
      <div className={optionClass}>{optionLabel}</div>
    </div>
  );

  const getSelectedFilterCounterWithMenu = (filter, selectedFiltersRest) => (
    <div
      className={classes.selectedFilterCounterContainer}
      automation-id={`heatmap-selected-filter-counter-with-menu-${filter.label}`}
    >
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger
          as="button"
          type="button"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className={classes.selectedFilterCounter}>+{selectedFiltersRest?.length}</div>
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
            <div className={classes.popupMenuContainer}>
              <div className={classes.popupTitle}>
                <GenerateIcon iconName={filter.icon} />
                <span className={classes.filterName}>{filter.label}</span>
              </div>
              {selectedFiltersRest?.map((option) =>
                filter.id === DROPDOWN_MAIN_FILTERS.SERVICES.id ? (
                  getSelectedServiceFilterMenuOption(option.label, classes.option)
                ) : (
                  <div className={classes.option}>{option.label}</div>
                ),
              )}
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );

  const getBasicSelectedFilterDisplay = (filter) => {
    const isExcluded = filtersContext?.excludedAdditionalFilters?.[filter.id];
    const isAdditionalDropDownFilter = Object.values(DROPDOWN_ADDITIONAL_FILTERS)?.find((f) => f.id === filter.id);

    const filterIcon = isExcluded ? filter.excludedIcon : filter.icon;
    const filterLabel = [FILTERS.ENRICHMENT_TAGS.id, FILTERS.CUSTOM_TAGS.id].includes(filter.id)
      ? selectedFilterValue?.[0].value
      : selectedFilterValue?.[0].label;
    const selectedFiltersRest = selectedFilterValue.slice(1);

    const filterTooltip = (
      <div className={classes.filterTooltip}>
        {filter.label}
        <div className={isExcluded ? classes.excludedText : classes.includedText}>
          {isAdditionalDropDownFilter ? `${isExcluded ? ` Excluded` : ` Included`}` : ''}
        </div>
      </div>
    );

    return (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id={`heatmap-selected-filter-${filter.label}`}
      >
        <Tooltip title={filterTooltip} placement="top">
          <div className="d-flex">
            {filter.icon ? (
              <div className={classes.selectedFilterIconContainer}>
                <GenerateIcon iconName={filterIcon} />
              </div>
            ) : null}
            {filterLabel ? <div className={classes.selectedFilterLabel}>{filterLabel}</div> : null}
          </div>
        </Tooltip>
        {selectedFiltersRest?.length > 0 ? getSelectedFilterCounterWithMenu(filter, selectedFiltersRest) : null}
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id={`heatmap-selected-filter-${filter}-close-button`}
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter: filter.id, isDropDown: true })}
          />
        )}
      </div>
    );
  };

  const getServicesSelectedFilterDisplay = (filter) => {
    const selectedFilterFirstThree = selectedFilterValue.slice(0, 3);
    const selectedFiltersRest = selectedFilterValue.slice(3);

    const filterCounter = selectedFilterValue?.length > 3 ? selectedFilterValue?.length - 3 : 0;

    return (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id="heatmap-selected-services-filter"
      >
        {filter.icon ? (
          <Tooltip title={filter.label} placement="bottom">
            <div className={classes.selectedFilterIconContainer}>
              <GenerateIcon iconName={filter.icon} />
            </div>
          </Tooltip>
        ) : null}
        <>
          {selectedFilterFirstThree?.map((option) =>
            getSelectedServiceFilterMenuOption(option.label, classes.serviceFilterLabel),
          )}
        </>
        {filterCounter ? getSelectedFilterCounterWithMenu(filter, selectedFiltersRest) : null}
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id={`heatmap-selected-filter-${filter.label}-close-button`}
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter: filter.id, isDropDown: true })}
          />
        )}
      </div>
    );
  };

  const getLabelOnlySelectedComponent = (filterLabel, filter) =>
    filterLabel ? (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id={`heatmap-selected-filter-${filter}`}
      >
        <div className={classes.selectedFilterLabelOnly}>{filterLabel}</div>
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id={`heatmap-selected-filter-${filter}-close-button`}
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter })}
          />
        )}
      </div>
    ) : null;

  const formatDisplayDate = (date) => moment(date).format('DD MMM YYYY');

  const getClosedAndDoneRecsDatesRange = () => {
    if (
      showOpenOnly ||
      (selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE] &&
        isEqual(selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE], DEFAULT_RECS_DATE_RANGE) &&
        selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR] === CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND &&
        !selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE])
    ) {
      return null;
    }

    const filterLabel = (
      <>
        <strong>Completed Date: </strong>
        {formatDisplayDate(
          selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]?.from ||
            selectedFilterValue.lastUpdateDate?.from,
        )}
        {' - '}
        {formatDisplayDate(
          selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]?.to ||
            selectedFilterValue.lastUpdateDate?.to,
        )}
        {selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE] ? (
          <>
            <strong>{` ${selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR]?.toUpperCase()} `}</strong>
            <strong>Creation Date: </strong>
            {formatDisplayDate(selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]?.from)}
            {' - '}
            {formatDisplayDate(selectedFilterValue[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]?.to)}
          </>
        ) : (
          ''
        )}
      </>
    );

    const filterIconName = useMemo(() => {
      let name = '';
      if (filtersContext[FILTERS.IS_OPEN.id] !== true) {
        name += 'Completed';
      }
      if (
        filtersContext[FILTERS.IS_OPEN.id] !== true &&
        filtersContext[FILTERS.USER_STATUS.id][USER_STATUS_FILTERS.ITEMS.DONE.id] !== false
      ) {
        name += '\\';
      }
      if (filtersContext[FILTERS.USER_STATUS.id][USER_STATUS_FILTERS.ITEMS.DONE.id] !== false) {
        name += 'Done';
      }
      return name;
    }, [filtersContext]);

    return (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id="heatmap-selected-close-and-done-date-range"
      >
        <div className={`${classes.selectedFilterIconContainer} ${classes.closed} `}>
          <GenerateIcon iconName={ICONS.calendarCheck.name} />
          {filterIconName}
        </div>
        <Tooltip title={filterLabel} placement="bottom">
          {filterLabel ? <div className={classes.selectedFilterLabel}>{filterLabel}</div> : null}
        </Tooltip>
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id="heatmap-selected-filter-open-date-range-close-button"
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter: FILTERS.CLOSED_AND_DONE_RECS_DATES.id, isDropDown: true })}
          />
        )}
      </div>
    );
  };

  const getOpenRecsDatesRange = () => {
    if (showDoneCompletedOnly || isEqual(selectedFilterValue, DEFAULT_RECS_DATE_RANGE)) {
      return null;
    }

    const filterLabel = (
      <>
        <strong>Creation Date: </strong>
        {formatDisplayDate(selectedFilterValue?.from)}
        {' - '}
        {formatDisplayDate(selectedFilterValue?.to)}
      </>
    );

    return (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id="heatmap-selected-open-date-range"
      >
        <div className={`${classes.selectedFilterIconContainer} ${classes.opened}`}>
          <GenerateIcon iconName={ICONS.calendar.name} />
          Open
        </div>
        <Tooltip title={filterLabel} placement="bottom">
          {filterLabel ? <div className={classes.selectedFilterLabel}>{filterLabel}</div> : null}
        </Tooltip>
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id="heatmap-selected-filter-open-date-range-close-button"
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter: FILTERS.OPEN_RECS_CREATION_DATE.id, isDropDown: true })}
          />
        )}
      </div>
    );
  };

  const getSelectedStatusFilterComponent = (filter) => {
    if (!selectedFilterValue) {
      return null;
    }

    const statusFilter = Object.values(STATUS_FILTER_PRESETS).find((option) => option.id === filtersContext[filter]);

    const statusFilterTooltip = useMemo(() => {
      if (!statusFilter) {
        return null;
      }

      let statusTooltip = statusFilter?.tooltip;
      if (filtersContext[filter] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id) {
        statusTooltip = tooltipTextForCustomStatusFilter(filtersContext);
      }

      return (
        <div className="d-flex flex-column fw-normal">
          <span>{`${statusFilter?.label} Status:`}</span>
          <span>{statusTooltip}</span>
          <span>Click to edit</span>
        </div>
      );
    }, [statusFilter, filtersContext]);

    return (
      <div className={classes.userStatusContainer}>
        <Tooltip title={statusFilterTooltip} placement="top" arrow>
          <div
            className={classes.selectedFilter}
            automation-id="heatmap-selected-filter-status"
            onClick={() => (!isStatusFilterOpen ? setIsStatusFilterOpen(true) : null)}
          >
            <div className={classes.selectedFilterIconContainer}>
              <GenerateIcon iconName={ICONS.clipboard.name} />
            </div>
            <div className={classes.selectedFilterLabel}>{statusFilter?.displayName}</div>
          </div>
        </Tooltip>
      </div>
    );
  };

  const getStarredSelectedFilterComponent = (filter) => {
    if (selectedFilterValue === null) {
      return null;
    }
    const filterIcon = selectedFilterValue === true ? ICONS.star.name : ICONS.regularStarSlash.name;
    const filterLabel =
      selectedFilterValue === true ? SWITCH_BUTTON_STATUSES.STARRED : SWITCH_BUTTON_STATUSES.NOT_STARRED;

    return (
      <div
        className={`${classes.selectedFilter} ${isDashboardPanel ? classes.isDisabled : ''}`}
        automation-id="heatmap-selected-filter-starred"
      >
        <Tooltip title={filterLabel} placement="bottom">
          <div
            className={`${classes.selectedFilterIconContainer} ${
              selectedFilterValue === true ? `${classes.starred}` : `${classes.notStarred}`
            }`}
          >
            <GenerateIcon iconName={filterIcon} />
          </div>
        </Tooltip>
        <div className={classes.selectedFilterLabel}>{filterLabel}</div>
        {!isDashboardPanel && (
          <GenerateIcon
            automation-id="heatmap-selected-filter-starred-close-button"
            iconName={ICONS.xMark.name}
            className={classes.closeButton}
            onClick={() => removeSelectedFilter({ filter })}
          />
        )}
      </div>
    );
  };

  const getSelectedFilterDisplay = () => {
    const allDropDownFilters = [...Object.values(DROPDOWN_ADDITIONAL_FILTERS), ...Object.values(DROPDOWN_MAIN_FILTERS)];
    const selectedDropDownFilter = allDropDownFilters?.find((filter) => filter.id === selectedFilter);
    if (selectedDropDownFilter) {
      if (!selectedFilterValue?.length) {
        return null;
      }
      if (selectedDropDownFilter.id === FILTERS.SERVICES.id) {
        return getServicesSelectedFilterDisplay(selectedDropDownFilter);
      }
      return getBasicSelectedFilterDisplay(selectedDropDownFilter);
    }
    if (selectedFilter === FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id) {
      return selectedFilterValue
        ? getLabelOnlySelectedComponent(`Annual Savings > ${selectedFilterValue?.toLocaleString()}`, selectedFilter)
        : null;
    }
    if (selectedFilter === FILTERS.STATUS_FILTER.id) {
      return getSelectedStatusFilterComponent(selectedFilter);
    }
    if (selectedFilter === FILTERS.IS_STARRED.id) {
      return getStarredSelectedFilterComponent(selectedFilter);
    }
    if (selectedFilter === FILTERS.OPEN_RECS_CREATION_DATE.id) {
      return getOpenRecsDatesRange();
    }
    if (selectedFilter === FILTERS.CLOSED_AND_DONE_RECS_DATES.id) {
      return getClosedAndDoneRecsDatesRange();
    }
    return null;
  };

  return <div>{getSelectedFilterDisplay()}</div>;
};

SelectedFilterDisplay.propTypes = {
  removeSelectedFilter: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
};

export default SelectedFilterDisplay;
