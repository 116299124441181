import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import ButtonAndt from 'shared/components/andtComponents/Button';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import {
  AwsCommonFields,
  CUSTOM_TAGS_NOT_TAGGED,
  serviceNameToDisplayNameWithShortName,
} from 'shared/constants/awsConstants';
import { uniqBy } from 'lodash/array';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import FieldWithSubMenuFilter from 'shared/components/FilterSidebar/FieldWithSubMenuFilter';
import { FilterSelect } from 'shared/components/FilterSidebar/FilterSelect';
import { IncludeExcludeButton } from 'shared/components/FilterSidebar/IncludeExcludeButton';
import { formatCUEFilterLabels } from 'invoices/utils/filtersUtils';
// eslint-disable-next-line max-len
import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import { debounce } from 'lodash';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { NEW_FILTER_FIELDS } from 'usage/constants/costAndUsageConstants';
import styles from './FieldSidebarFilter.module.scss';
import SimpleSelect from '../andtComponents/SimpleSelect';

const createOptions = (fieldValues, filterType) =>
  fieldValues.map((fieldValue) => formatFilterOptionValueLabel(fieldValue, filterType));

const FieldSidebarFilter = ({
  field,
  label,
  options,
  handleChange,
  selectedOptions,
  excludeMode,
  handleChangeFilterType,
  disableExclude,
  isOneChoiceFieldFilter,
  keys,
  isDisabled,
  tooltip,
  filterIcon,
  conjunctionSelect,
  conjunctionConfig,
  likeMode,
  likeCaseConfig,
  subMenu,
  isAutoComplete,
  likeOperator,
  filter,
  isAsyncLoad,
  isAsyncLoadClick,
  fieldsWidth,
  fetchData,
  fetchSubOptionsData,
  onOpen,
  toFormatData,
  showKeysOfSubMenuLoading,
  showSubOptionsLoading,
  hideSubMenuExclude = false,
  className,
  hideSelectAll,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [asyncLoaded, setAsyncLoaded] = useState(false);
  const [subMenuOpenTagKey, setSubMenuOpenTagKey] = useState(false);
  const [subMenuOpenTagLabel, setSubMenuOpenTagLabel] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [asyncLoadSearchText, setAsyncLoadSearchText] = useState('');
  const { data, isLoading } = fetchData(
    isAsyncLoadClick ? asyncLoadSearchText : searchText,
    subMenuOpenTagKey,
    isAsyncLoad && isOpen,
  );
  const { data: subOptionsData } =
    fetchSubOptionsData && subMenuOpenTagKey
      ? fetchSubOptionsData(subMenuOpenTagKey, isAsyncLoadClick ? asyncLoadSearchText : searchText)
      : {};

  const getSubMenuOptions = () => (fetchSubOptionsData ? subOptionsData : createOptions(data || [], field));
  function onSetIsOpen(isOpen) {
    if (onOpen) {
      onOpen(field);
    }
    setIsOpen(isOpen);
  }

  const getFilteredOptions = (options) =>
    (options || [])
      .filter(({ label, value }) => {
        const labelToDisplay = serviceNameToDisplayNameWithShortName.get(label) || label;
        const searchLower = searchText.toLowerCase();
        return (
          labelToDisplay &&
          (labelToDisplay.toLowerCase().includes(searchLower) ||
            value.toLowerCase().includes(searchLower) ||
            value.endsWith('no_tag'))
        );
      })
      .filter(filter || (() => true));

  const toggleOptionList = (e) => {
    e.preventDefault();
    if (!isOpen) {
      segmentEvent({
        type: 'click-event',
        target: 'fieldSidebarFilter',
        data: { field },
      });
    } else {
      setSearchText('');
    }
    onSetIsOpen(!isOpen);
  };

  const handleSelectAllOptions = (isChecked, options, subField) => {
    if (!isChecked) {
      handleChange(
        field,
        subMenu
          ? selectedOptions?.filter((opt) =>
              subField ? !opt.value.startsWith(`${subField}: `) || !options.find((o) => o.value === opt.value) : false,
            )
          : selectedOptions?.filter((s) => !options.some((o) => o.value === s.value)),
      );
      return;
    }
    handleChange(field, uniqBy([...(selectedOptions ?? []), ...options], 'value'));
  };

  const handleSearchInputChange = debounce((str) => {
    setSearchText(str || '');
  }, 500);

  const handleSubMenuOpen = (searchStr) => {
    setSearchText(searchStr || '');
  };

  const handleRemoveFieldFilterOption = (option) => {
    const { value } = option;
    handleChange(
      field,
      selectedOptions.filter((opt) => opt.value !== value),
    );
  };

  const changeFilterType = (e, field, subField, isExclude) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    handleChangeFilterType(field, subField, isExclude);
  };

  const getIsAllSelected = (options) =>
    getFilteredOptions(options).every(({ value }) => selectedOptions?.some((opt) => opt.value === value));

  const filterSelectedChange = (selectedItems, options, isSubMenuOptions) => {
    if (!selectedItems) {
      return;
    }
    let subFieldName = null;
    if (isSubMenuOptions) {
      subFieldName =
        selectedItems.length > 0 ? selectedItems[0]?.value.split(': ')[0] : options[1]?.value.split(': ')[0];
    }
    if (selectedItems.length === options?.length) {
      const optionsToShow = options && (getFilteredOptions(options) || []);
      handleSelectAllOptions(!getIsAllSelected(options), optionsToShow, subFieldName);
    } else {
      if (subFieldName) {
        const oldSelectedOptions = selectedOptions
          ? selectedOptions.filter(
              (opt) => !opt.value?.startsWith(`${subFieldName}: `) || !options.find((o) => o.value === opt.value),
            )
          : [];
        const test = [...uniqBy([...oldSelectedOptions, ...selectedItems], 'value')];
        handleChange(field, test);
      } else {
        handleChange(field, !isOneChoiceFieldFilter ? selectedItems : [selectedItems]);
      }
      if (selectedItems.length === 0) {
        handleSelectAllOptions(false, options, subFieldName);
      }
    }
  };
  const renderOption = (data) => {
    const subMenuSelectedItems = selectedOptions?.filter((option) =>
      data.find((selectOption) => selectOption.value === option.value),
    );
    return (
      <>
        <FilterSelect
          subMenuOpenTagKey={subMenuOpenTagKey}
          onChange={(selectedItems) =>
            filterSelectedChange(selectedItems, fetchSubOptionsData ? subOptionsData : data, true)
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isMulti
          field={field}
          selectedItems={subMenuSelectedItems || []}
          isLoading={isLoading || showSubOptionsLoading}
          showKeysOfSubMenuLoading={showSubOptionsLoading}
          width={fieldsWidth}
          hideSelectAll={hideSelectAll}
          searchHandler={(value) => handleSearchInputChange(value)}
          limitOnBlurInId="backButton"
          onMenuBlur={(event) => {
            if (event.relatedTarget?.id !== `toggle-${field}`) {
              onSetIsOpen(false);
            }
            setSearchText('');
            setSubMenuOpenTagKey(false);
          }}
          subOptions={formatCUEFilterLabels(
            field,
            (fetchSubOptionsData ? subOptionsData || [] : data).filter((d) => d.value !== null),
          )}
        />
      </>
    );
  };

  const renderBudgesList = (options, excludeMode) => {
    if (isOpen || isEmptyArray(options)) {
      return null;
    }
    return (
      <div className={`${className?.badgeContainer}  ${styles.badgeContainer}`}>
        {options.slice(0, 5).map((selectedOptionValue) => {
          const label =
            field === AwsCommonFields.ACCOUNT_TAGS
              ? selectedOptionValue.label
              : LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', selectedOptionValue.label);
          return (
            <div
              className={`${styles.badge} ${excludeMode ? styles.exclude : styles.sidebarFilterInclude}`}
              key={selectedOptionValue.value}
            >
              <span>
                {likeMode ? (
                  <>
                    Contains ({label})
                    {likeCaseConfig?.get(field) ? <span className={styles.caseNote}>case sensitive</span> : null}
                  </>
                ) : (
                  label
                )}
              </span>
              <button
                type="button"
                className={styles.noStyle}
                disabled={isDisabled}
                onClick={() => handleRemoveFieldFilterOption(selectedOptionValue)}
              >
                <CloseRoundedIcon />
              </button>
            </div>
          );
        })}
        {options.length > 5 && (
          <div className={`${styles.badge} ${excludeMode ? styles.exclude : ''}`}>
            <span className="bold-text">And {options.length - 5} more</span>
          </div>
        )}
      </div>
    );
  };

  const renderSubFieldConjunction = (key) => (
    <div className={styles.conjunctionSelectContainer}>
      <SimpleSelect
        isClearable={false}
        className={styles.conjunctionSelect}
        value={conjunctionConfig?.get(key) || 'and'}
        onChange={(value) => conjunctionConfig?.set(key, value)}
        options={[
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ]}
      />
    </div>
  );

  const renderGroupedBudgesList = (options) => {
    if (isOpen || isEmptyArray(options)) {
      return null;
    }
    const byKey = new Map();
    options.forEach((opt) => {
      if (!opt.value) {
        return;
      }
      const [key, value] = opt.value?.split(': ');
      const [, label] = opt.label?.split(': ');
      byKey.set(key, [
        ...(byKey.get(key) || []),
        {
          ...opt,
          label: field === AwsCommonFields.BUSINESS_MAPPING ? label || opt.label : value || opt.value,
        },
      ]);
    });
    return (
      <div className="mt-3">
        {[...byKey.keys()].map((key, index) => {
          const isExclude = excludeMode && excludeMode[key];
          const renderConjunction = () =>
            conjunctionSelect && index !== [...byKey.keys()].length - 1 ? renderSubFieldConjunction(field) : null;
          if (key === CUSTOM_TAGS_NOT_TAGGED) {
            return (
              <React.Fragment key={key}>
                <div className={styles.grouped}>
                  <div className="d-flex">
                    <span className={styles.fieldName}>Not tagged</span>
                    <button
                      type="button"
                      className={`${styles.noStyle} ${styles.marginTop}`}
                      disabled={isDisabled}
                      onClick={() => handleRemoveFieldFilterOption({ value: CUSTOM_TAGS_NOT_TAGGED })}
                    >
                      <CloseRoundedIcon />
                    </button>
                  </div>
                </div>
                {renderConjunction()}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={key}>
              <div>
                <div
                  className={styles.grouped}
                  onClick={(e) => {
                    toggleOptionList(e);
                    setSubMenuOpenTagKey(key);
                  }}
                >
                  <div className="d-flex">
                    <span className={styles.fieldName}>
                      {LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key)}
                    </span>
                    {!isDisabled && (
                      <ButtonAndt
                        isTextButton
                        text="Clear All"
                        disabled={isDisabled}
                        overrideStyles={{
                          fontSize: '11px',
                          height: 16,
                          fontWeight: 400,
                          marginTop: '7px',
                          padding: '0',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectAllOptions(false, options, key);
                        }}
                      />
                    )}
                  </div>
                  {!hideSubMenuExclude && (
                    <IncludeExcludeButton
                      excludeMode={isExclude}
                      field={field}
                      label=""
                      subField={key}
                      changeFilterType={changeFilterType}
                      isDisabled={isDisabled}
                    />
                  )}
                  {/* {this.renderIncludeExcludeButton(isExclude, field, '', false, key)} */}
                </div>
                {renderBudgesList(byKey.get(key), isExclude)}
              </div>
              {renderConjunction()}
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  const getNewOptions = () => {
    const fetchedOptions =
      // eslint-disable-next-line no-nested-ternary
      data && data.length > 0 ? (toFormatData !== false ? createOptions(data || [], field) : data) : options;
    const optionsTemp = subMenu
      ? (keys || [])
          .filter((key) => key.toLowerCase().includes(searchText.toLowerCase()))
          .map((key) => ({
            label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key),
            value: key,
          }))
      : fetchedOptions;

    const finalOptions = formatCUEFilterLabels(
      field,
      optionsTemp.filter((o) => o.value !== null),
    );

    return finalOptions;
  };

  const getEmptyStateText = () => {
    if (!isAsyncLoadClick && !isAutoComplete) {
      return null;
    }
    return isAsyncLoadClick
      ? 'Type and click on the search icon to view results'
      : 'Enter a minimum of three characters to view the results';
  };
  useEffect(() => {
    if (isOpen && isAsyncLoad && !asyncLoaded) {
      setAsyncLoaded(true);
    }
  }, [isOpen, isAsyncLoad, asyncLoaded, setAsyncLoaded]);

  const getParentKeysForSubMenus = () =>
    // when keys is static list use key properties - when its dynamic fetch api use data
    Array.isArray(keys)
      ? (keys || [])
          .filter((key) => key.toLowerCase().includes(searchText.toLowerCase()))
          .map((key) => ({
            label:
              field === AwsCommonFields.ACCOUNT_TAGS
                ? key
                : LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key),
            value: key,
          }))
      : data;
  return (
    <div className={`${className?.sideBarField} ${styles.sideBarField}  ${isOpen ? styles.isSelect : ''}`}>
      <button
        automation-id={`${field}-filter`}
        type="button"
        className={`${className?.fieldNameContainer} ${styles.fieldNameContainer} ${isDisabled ? styles.disabled : ''}`}
        id={`toggle-${field}`}
        onClick={isDisabled ? undefined : (e) => toggleOptionList(e)}
      >
        <div className={styles.flexContainer}>
          <div className={styles.filterTitle}>
            {filterIcon ? <GenerateIcon iconName={filterIcon} className={styles.filterIcon} /> : null}
            <span className={styles.fieldName}>
              {label || LabelCoordinator.getFieldLabel(field)}
              {selectedOptions?.length > 0 && <span className={styles.selectLabel}>({selectedOptions?.length})</span>}
              {tooltip && (
                <Tooltip title={tooltip} arrow className={styles.marginLeft}>
                  <span>
                    <InfoIcon />
                  </span>
                </Tooltip>
              )}
              {NEW_FILTER_FIELDS.includes(field) && (
                <span className={styles.newField}>
                  NEW
                  <GenerateIcon iconName={ICONS.sparkles.name} />
                </span>
              )}
            </span>
          </div>
          {isEmptyArray(selectedOptions) || isDisabled ? null : (
            <ButtonAndt
              isTextButton
              text="Clear All"
              disabled={isDisabled}
              overrideStyles={{ fontSize: '12px', height: 16, fontWeight: 400, padding: '0' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleChange(field, []);
              }}
            />
          )}
        </div>
        <div className={styles.flexContainer}>
          {disableExclude || subMenu ? null : (
            <IncludeExcludeButton
              excludeMode={!!excludeMode}
              field={field}
              label={label}
              changeFilterType={changeFilterType}
              isDisabled={isDisabled}
            />
          )}
          <span className={styles.smallWidth} />
        </div>
      </button>
      <div className={styles.noMargin}>
        {subMenu ? renderGroupedBudgesList(selectedOptions) : renderBudgesList(selectedOptions, excludeMode)}
        {subMenu ? (
          isOpen && (
            <FieldWithSubMenuFilter
              subMenuOpenTagKey={subMenuOpenTagKey}
              subMenuOpenTagLabel={subMenuOpenTagLabel}
              setSubMenuOpenTagKey={(menuOpenKey) => {
                setSubMenuOpenTagKey(menuOpenKey?.value || menuOpenKey);
                setSubMenuOpenTagLabel(menuOpenKey?.label || menuOpenKey);
                if (menuOpenKey) {
                  handleSubMenuOpen('');
                }
              }}
              options={subMenuOpenTagKey ? getSubMenuOptions() : getParentKeysForSubMenus()}
              backClick={() => {
                setSearchText('');
                setSubMenuOpenTagKey(null);
                setSubMenuOpenTagLabel(null);
                onSetIsOpen(true);
                setSearchText('');
              }}
              setIsOpen={(isOpen) => onSetIsOpen(isOpen)}
              field={field}
              isLoading={isLoading}
              showKeysOfSubMenuLoading={showKeysOfSubMenuLoading}
              isOpen={isOpen}
              searchText={searchText}
              renderOption={renderOption}
              selectedOptions={selectedOptions}
              handleClearSearch={() => handleSubMenuOpen('')}
              specialOptionChange={(selectItem) => {
                const oldSelectedOptions = selectedOptions.filter(
                  (opt) => !opt.value?.startsWith(`${selectItem.value}`),
                );
                if (selectItem.isChecked) {
                  handleChange(field, [...oldSelectedOptions, selectItem]);
                } else {
                  handleChange(field, [...oldSelectedOptions]);
                }
              }}
            />
          )
        ) : (
          <FilterSelect
            subMenuOpenTagKey={subMenuOpenTagKey}
            onChange={(selectedItems) => filterSelectedChange(selectedItems, getNewOptions(), false)}
            isOpen={isOpen}
            setIsOpen={(isOpen) => {
              onSetIsOpen(isOpen);
            }}
            field={field}
            isMulti={!isOneChoiceFieldFilter}
            hideFooter={isOneChoiceFieldFilter}
            searchHandler={(value) => handleSearchInputChange(value)}
            limitOnBlurInId={`toggle-${field}`}
            likeParams={
              likeOperator
                ? {
                    likeOperator,
                    likeMode,
                    handleChangeFilterType,
                    field,
                    searchHandler: (value) => handleChange(field, [{ value, label: value }]),
                    likeCaseConfig,
                  }
                : {}
            }
            isLoading={isLoading}
            onMenuBlur={() => {
              onSetIsOpen(false);
              setSearchText('');
            }}
            options={getNewOptions()}
            selectedItems={selectedOptions}
            hideSelectAll={hideSelectAll}
            emptyStateText={getEmptyStateText()}
            onSearchClick={(searchText) => {
              if (isAsyncLoadClick) {
                setAsyncLoadSearchText(searchText);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FieldSidebarFilter;

FieldSidebarFilter.propTypes = {
  field: PropTypes.string.isRequired,
  keys: PropTypes.array,
  isAutoComplete: PropTypes.bool,
  isAsyncLoad: PropTypes.bool,
  subMenu: PropTypes.bool,
  likeOperator: PropTypes.bool,
  likeMode: PropTypes.bool,
  filter: PropTypes.func,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  selectedOptions: PropTypes.array.isRequired,
  excludeMode: PropTypes.object,
  handleChangeFilterType: PropTypes.func.isRequired,
  conjunctionConfig: PropTypes.object,
  likeCaseConfig: PropTypes.object,
  isOneChoiceFieldFilter: PropTypes.bool,
  disableExclude: PropTypes.bool,
  isAsyncLoadClick: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  conjunctionSelect: PropTypes.bool,
  filterIcon: PropTypes.string,
  fieldsWidth: PropTypes.number,
  fetchData: PropTypes.func,
  toFormatData: PropTypes.bool,
  onOpen: PropTypes.func,
};

FieldSidebarFilter.defaultProps = {
  isDisabled: false,
  filter: null,
  likeOperator: false,
  likeMode: false,
  keys: [],
  excludeMode: null,
  conjunctionSelect: false,
  conjunctionConfig: null,
  likeCaseConfig: null,
  isOneChoiceFieldFilter: false,
  disableExclude: false,
  isAutoComplete: false,
  isAsyncLoad: false,
  isAsyncLoadClick: false,
  subMenu: false,
  label: '',
  tooltip: null,
  filterIcon: null,
  fieldsWidth: 0,
  fetchData: () => ({ data: [] }),
  toFormatData: true,
  onOpen: null,
};
