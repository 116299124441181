import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { ReactComponent as EmptyGroupByCardData } from 'recommendationsNew/img/noData.svg';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { isDecimalNeeded, kIntFormmater, strNumToSize } from 'shared/utils/strUtil';
import { CustomizedLegend } from './customizedLegend';
import { CHART_LINE_COLORS, Y_AXIS_UNIT } from './chartConsts';
import { CustomizedTooltip } from './customizedTooltip';
import { CustomizedActiveDot } from './customizedActiveDot';

import classes from './genericChart.module.scss';

const GenericChart = ({ data, yAxisLabel, yAxisUnit, linesList, referenceLinesList, effectiveTimeFrame }) => {
  if (!data?.length) {
    return (
      <div className="mb-4">
        <EmptyGroupByCardData className="m-auto d-flex" />
      </div>
    );
  }
  const formatYValue = (value) => {
    let formattedValue = value;
    if (yAxisUnit === Y_AXIS_UNIT.BYTE) {
      formattedValue = value === 0 ? value : strNumToSize(value, 0);
    } else if (yAxisUnit === Y_AXIS_UNIT.PERCENT) {
      formattedValue = `${kIntFormmater(value, 2, false)}%`;
    } else if (yAxisUnit === Y_AXIS_UNIT.MB) {
      formattedValue = `${kIntFormmater(value, 2, false)}MB`;
    } else if (yAxisUnit === Y_AXIS_UNIT.MILICPU) {
      formattedValue = `${Math.abs(value).toFixed(isDecimalNeeded(Math.abs(value), 2) ? 2 : 0)} milliCores`;
    } else {
      formattedValue = value.toFixed(2);
    }
    return formattedValue;
  };

  const legendWrapperStyle = {
    bottom: '0',
    left: '0',
    position: 'relative',
    width: '99%',
  };

  const [turnedOffLines, setTurnedOffLines] = useState([]);

  const linesToDisplay = useMemo(
    () => linesList.filter((line) => !turnedOffLines.includes(line.id)),
    [linesList, turnedOffLines],
  );

  const maxYAxisValue = useMemo(() => {
    const linesMax = Math.max(...data.map((item) => Math.max(...linesToDisplay.map((line) => item[line.id]))));
    const referenceMax = Math.max(...referenceLinesList.map((line) => line.yAxisValue));
    return Math.max(linesMax, referenceMax);
  }, [data, linesToDisplay, referenceLinesList]);

  const onClickLegendPair = (entry) => {
    if (turnedOffLines.includes(entry.currentTarget.id)) {
      setTurnedOffLines(turnedOffLines.filter((lineId) => lineId !== entry.currentTarget.id));
    } else {
      setTurnedOffLines([...turnedOffLines, entry.currentTarget.id]);
    }
  };

  const [clickedLineId, setClickedLineId] = useState(null);

  const handleLineClick = (entry) => {
    if (clickedLineId === entry.id) {
      setClickedLineId(null);
    } else {
      setClickedLineId(entry.id);
    }
  };
  const referenceLinesForEqualValuesInLine = useMemo(
    () =>
      linesList
        .map((line, index) => {
          const allItemsEqual = data.every((item) => item[line.id] === data[0][line.id]);
          return allItemsEqual ? { ...line, index } : null;
        })
        .filter(Boolean),
    [linesList, data],
  );

  const colorsKeys = Object.keys(CHART_LINE_COLORS);
  const zeroValuesLineIndex = linesToDisplay.findIndex((line) => data.every((item) => item[line.id] === 0));
  return (
    <ResponsiveContainer className={classes.customChartContainer} width="100%" height={254}>
      <LineChart width={550} height={284} data={data} margin={{ top: 20, right: 20, bottom: -30, left: 20 }}>
        <CartesianGrid horizontal vertical={false} stroke={palette.gray[200]} />
        <XAxis
          axisLine={{ stroke: palette.gray[300] }}
          dataKey="usageDate"
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          type="category"
        />
        <YAxis
          axisLine={false}
          domain={[0, maxYAxisValue > 1 ? maxYAxisValue : 'auto']}
          label={{
            value: yAxisLabel,
            dy: -15,
            dx: 1,
            angle: -90,
            position: 'left',
          }}
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          tickFormatter={(value) => formatYValue(value)}
          type="number"
        />
        <Tooltip
          content={
            <CustomizedTooltip turnedOffLines={turnedOffLines} clickedLineId={clickedLineId} yAxisUnit={yAxisUnit} />
          }
        />
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
            <feOffset dx="2" dy="4" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.25" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <defs>
          <filter id="effectiveShadow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="7.5" />
            <feOffset dx="0" dy="0" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.25" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        {effectiveTimeFrame && (
          <ReferenceArea
            x1={createDateDisplayStr(effectiveTimeFrame)}
            x2={data[data.length - 1].usageDate}
            fillOpacity={0.7}
            fill="#fff"
            filter="url(#effectiveShadow)"
          />
        )}
        {linesToDisplay?.map((line, index) => (
          <Line
            className={`${classes.customLine} ${turnedOffLines.includes(`${line.id}Legend`) && classes.hiddenLine}`}
            dataKey={line.id}
            dot={false}
            activeDot={<CustomizedActiveDot />}
            id={line.id}
            name={line.label}
            onClick={handleLineClick}
            stroke={
              clickedLineId && clickedLineId !== line.id
                ? palette.gray[300]
                : CHART_LINE_COLORS[colorsKeys[index]].color
            }
            strokeWidth={1.5}
            type="monotone"
            filter="url(#shadow)"
          />
        ))}
        {referenceLinesList?.map((line, index) => (
          <ReferenceLine
            y={line.yAxisValue}
            label={{
              value: line.label,
              offset: 5,
              position: 'top',
              fill: CHART_LINE_COLORS[colorsKeys[index + linesToDisplay.length]].color,
              fontSize: 12,
            }}
            stroke={CHART_LINE_COLORS[colorsKeys[index + linesToDisplay.length]].color}
            strokeWidth={1}
            strokeDasharray="6 6"
          />
        ))}
        {referenceLinesForEqualValuesInLine?.map(
          (line) =>
            !turnedOffLines.includes(`${line.id}Legend`) && (
              <ReferenceLine
                y={data[0][line.id]}
                label={{
                  value: '',
                  offset: 5,
                  position: 'top',
                  fill: CHART_LINE_COLORS[colorsKeys[line.index]].color,
                  fontSize: 12,
                }}
                stroke={CHART_LINE_COLORS[colorsKeys[line.index]].color}
                strokeWidth={1}
              />
            ),
        )}
        {zeroValuesLineIndex > -1 && (
          <ReferenceLine y={0} stroke={CHART_LINE_COLORS[colorsKeys[zeroValuesLineIndex]].color} />
        )}

        <Legend
          content={
            <CustomizedLegend
              turnedOffLines={turnedOffLines}
              onClickLegendPair={onClickLegendPair}
              effectiveTime={effectiveTimeFrame}
            />
          }
          wrapperStyle={legendWrapperStyle}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

GenericChart.propTypes = {
  data: PropTypes.object.isRequired,
  linesList: PropTypes.array.isRequired,
  referenceLinesList: PropTypes.array,
  yAxisLabel: PropTypes.string.isRequired,
  yAxisUnit: PropTypes.string,
};

GenericChart.defaultProps = {
  yAxisUnit: Y_AXIS_UNIT.OTHER,
  referenceLinesList: [],
};

export default GenericChart;
