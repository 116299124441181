/* eslint-disable max-len */
import { isRouteAllowed, Routes } from 'shared/constants/routes';
import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS, UsersAccountTypeId, UsersType } from 'users/constants/usersConstants';

// menu options common populations
const ALL_USER_ACCOUNT_TYPES = [
  UsersAccountTypeId.REGULAR,
  UsersAccountTypeId.RESELLER,
  UsersAccountTypeId.MULTI_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_ACCOUNT,
  UsersAccountTypeId.MULTI_CLOUDS_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT,
  UsersAccountTypeId.M3C_ACCOUNT,
];
const ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD = [
  UsersAccountTypeId.REGULAR,
  UsersAccountTypeId.RESELLER,
  UsersAccountTypeId.MULTI_ACCOUNT,
  UsersAccountTypeId.RESELLER_MULTI_ACCOUNT,
];

const ALL_CLOUD_TYPES = [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE, CLOUD_TYPE_IDS.MULTI];
const ALL_CLOUD_TYPES_EXCEPT_MULTI = [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE];

const ALL_VALID_USERS = [
  UsersType.SUPER_USER,
  UsersType.USER,
  UsersType.BETA_USER,
  UsersType.USER_ON_BOARDED,
  UsersType.SUB_USER,
  UsersType.RESELLER,
  UsersType.RESELLER_CUSTOMER,
  UsersType.RESELLER_CUSTOMER_EDP,
  UsersType.BUSINESS_ADMIN,
  UsersType.RESELLER_BUSINESS_ADMIN,
];

const isK8sDisabled = ({ accountTypeId, accountFeatures }) => {
  if (
    !(accountFeatures || []).includes(ACCOUNT_FEATURES.K8S) ||
    [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT].includes(accountTypeId)
  ) {
    return true;
  }
  return false;
};
const isK8sPreviewDisabled = (params) => !isK8sDisabled({ ...params });

const sideBarMenuOptions = [
  {
    value: 'dashboard',
    title: 'Dashboard',
    route: Routes.DASHBOARD,
    icon: ICONS.objectsColumn.name,
    usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
    cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
    userTypes: ALL_VALID_USERS,
  },
  {
    value: 'recommendations',
    title: 'Recommendations',
    icon: ICONS.recommendations.name,
    disableFF: ACCOUNT_FEATURES.RECOMMENDATIONS,
    children: [
      {
        value: 'dashboard',
        title: 'Dashboard',
        route: `${Routes.RECOMMENDATIONS_DASHBOARD}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'dailyRecommendations',
        title: 'Daily Recommendations',
        route: `${Routes.DAILY_RECOMMENDATIONS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],
      },
      {
        value: 'history',
        title: 'History',
        route: `${Routes.RECOMMENDATION_HISTORY}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'reports',
        title: 'Reports',
        route: `${Routes.RECOMMENDATIONS_REPORTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'exclusions',
        title: 'Exclusions',
        route: `${Routes.MANAGE_RECOMMENDATIONS}`,
        enableFF: ACCOUNT_FEATURES.EXCLUSIONS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'preferences',
        title: 'Preferences',
        route: `${Routes.RECOMMENDATION_PREFERENCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,

        disableFF: ACCOUNT_FEATURES.RECOMMENDATIONS_PREFERENCES,
      },
      {
        value: 'preferencesNew',
        title: 'Preferences',
        route: `${Routes.RECOMMENDATION_PREFERENCES_NEW}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,

        enableFF: ACCOUNT_FEATURES.RECOMMENDATIONS_PREFERENCES,
      },
    ],
  },
  {
    value: 'recommendationsNew',
    title: 'Recommendations',
    icon: ICONS.recommendations.name,
    enableFF: ACCOUNT_FEATURES.RECOMMENDATIONS,
    children: [
      // {
      //   value: 'recommendation360',
      //   title: 'Recommendation 360°',
      //   route: `${Routes.RECOMMENDATION_360}`,
      //   usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
      //   cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
      //   userTypes: ALL_VALID_USERS,
      // },
      {
        value: 'recommendationExplorer',
        title: 'Waste Detector',
        route: `${Routes.RECOMMENDATION_EXPLORER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'reports',
        title: 'Reports',
        route: `${Routes.RECOMMENDATIONS_REPORTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'preferences',
        title: 'Preferences',
        route: `${Routes.RECOMMENDATION_PREFERENCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,

        disableFF: ACCOUNT_FEATURES.RECOMMENDATIONS_PREFERENCES,
      },
      {
        value: 'preferencesNew',
        title: 'Preferences',
        route: `${Routes.RECOMMENDATION_PREFERENCES_NEW}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,

        enableFF: ACCOUNT_FEATURES.RECOMMENDATIONS_PREFERENCES,
      },
    ],
  },
  {
    value: 'costAndUsage',
    title: 'Cost & Usage',
    icon: ICONS.costAndUsage.name,
    children: [
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.COST_USAGE_EXPLORER}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'reports',
        title: 'Reports',
        route: `${Routes.REPORTS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'dashboards',
        title: 'Dashboards',
        route: `${Routes.CUSTOM_DASHBOARD}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'panels',
        title: 'Panels',
        route: `${Routes.CUSTOM_DASHBOARDS_PANELS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'assets',
        title: 'Assets',
        route: `${Routes.ASSETS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'resourceExplorer',
        title: 'Resource Explorer',
        route: `${Routes.RESOURCE_EXPLORER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'metricsExplorer',
        title: 'Metrics Explorer',
        route: `${Routes.METRICS_EXPLORER}`,
        enableFF: ACCOUNT_FEATURES.METRICS_EXPLORER,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
    ],
  },
  {
    value: 'costAllocation',
    title: 'Cost Allocation',
    icon: ICONS.linkHorizontal.name,
    children: [
      {
        value: 'views',
        title: 'Views',
        route: `${Routes.VIEWS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'virtualTags',
        title: 'Virtual Tags',
        route: `${Routes.VIRTUAL_TAGS}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'filterGroup',
        title: 'Filter Group',
        route: Routes.FILTER_GROUP,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'businessMapping',
        title: 'Business Mapping',
        route: Routes.BUSINESS_MAPPING,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'enrichmentTags',
        title: 'Enrichment Tags',
        route: Routes.ACCOUNT_TAGS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'commitment',
    title: 'Commitment',
    icon: ICONS.commitment.name,
    children: [
      {
        value: 'dashboard',
        title: 'Dashboard',
        route: `${Routes.COMMITMENT_DASHBOARD}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'spAnalyzer',
        title: 'SP Analyzer',
        route: `${Routes.SAVINGS_PLANS_ANALYZER}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'spAnalyzerNew',
        title: 'SP Analyzer New',
        route: `${Routes.SAVINGS_PLANS_ANALYZER_NEW}`,
        enableFF: ACCOUNT_FEATURES.SP_ANALYZER_NEW,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'riUtilization',
        title: 'RI Utilization',
        route: `${Routes.RI_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER_CUSTOMER,
        ],

        isDisabled({ cloudTypeId, usersStore }) {
          if (cloudTypeId === CLOUD_TYPE_IDS.AZURE) {
            const isPAYG = usersStore.accountInfo.azure_interface_type === 'PAYG_EXPORT';
            if (!usersStore?.currUserInitDone || isPAYG) {
              return true;
            }
          }
          return false;
        },
      },
      {
        value: 'spUtilization',
        title: 'SP Utilization',
        route: `${Routes.SAVINGS_PLANS_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
      // For multi account - enable only in AWS
      {
        value: 'riUtilization',
        title: 'RI Utilization',
        route: `${Routes.RI_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
      {
        value: 'spUtilization',
        title: 'SP Utilization',
        route: `${Routes.SAVINGS_PLANS_UTILIZATION}`,
        usersAccountTypes: [UsersAccountTypeId.MULTI_ACCOUNT, UsersAccountTypeId.RESELLER_MULTI_ACCOUNT],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
          UsersType.USER_ON_BOARDED,
        ],
      },
    ],
  },
  {
    value: 'services',
    title: 'Services',
    icon: ICONS.sitemap.name,
    children: [
      {
        value: 'ec2',
        title: 'EC2',
        route: `${Routes.EC2}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'rds',
        title: 'RDS',
        route: `${Routes.RDS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 's3',
        title: 'S3',
        route: `${Routes.S3}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'k8s',
        title: 'K8S',
        route: `${Routes.K8S_USAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.SUPER_USER],
      },
      {
        value: 'virtualMachines',
        title: 'Virtual Machines',
        route: `${Routes.AZURE_VIRTUAL_MACHINES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'blockBlobStorage',
        title: 'Block Blob Storage',
        route: `${Routes.AZURE_STORAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'dbCosts',
        title: 'DB Costs',
        route: `${Routes.AZURE_DB_COSTS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'bigQuery',
        title: 'Big Query',
        route: `${Routes.BIG_QUERY}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.GCP],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.USER,
          UsersType.BETA_USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
    ],
  },
  {
    value: 'kubernetes',
    title: 'Kubernetes',
    icon: ICONS.kubernetes.name,
    children: [
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.K8S_USAGE}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],

        isDisabled: isK8sDisabled,
      },
      // K8s - CUE - preview
      {
        value: 'costAndUsageExplorer',
        title: 'Cost & Usage Explorer',
        route: `${Routes.K8S_PREVIEW}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,

        isDisabled: isK8sPreviewDisabled,
      },
      {
        value: 'preferences',
        title: 'Preferences',
        route: `${Routes.K8S_PREFERENCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],

        isDisabled: isK8sDisabled,
      },
      {
        value: 'assets',
        title: 'Assets',
        route: `${Routes.K8S_ASSETS}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.USER_ON_BOARDED,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER,
          UsersType.RESELLER_CUSTOMER_EDP,
        ],

        isDisabled: isK8sDisabled,
      },
    ],
  },
  {
    value: 'monitoring',
    title: 'Monitoring',
    icon: ICONS.monitor.name,
    iconSize: '24px',
    children: [
      {
        value: 'anomalyDetection',
        title: 'Anomaly Detection',
        route: `${Routes.ANOMALY_DETECTION}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'budget',
        title: 'Budget',
        route: `${Routes.BUDGET}`,
        usersAccountTypes: [
          UsersAccountTypeId.REGULAR,
          UsersAccountTypeId.RESELLER,
          UsersAccountTypeId.MULTI_CLOUDS_ACCOUNT,
          UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT,
          UsersAccountTypeId.M3C_ACCOUNT,
        ],
        cloudTypes: ALL_CLOUD_TYPES,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'idleInstances',
        title: 'Idle Instances',
        route: `${Routes.IDLE_INSTANCES}`,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [
          UsersType.SUPER_USER,
          UsersType.BETA_USER,
          UsersType.USER,
          UsersType.SUB_USER,
          UsersType.RESELLER,
          UsersType.RESELLER_CUSTOMER_EDP,
          UsersType.BUSINESS_ADMIN,
          UsersType.RESELLER_BUSINESS_ADMIN,
        ],
      },
      {
        value: 'alerts',
        title: 'Alerts',
        route: `${Routes.CUE_ALERTS}`,
        disableFF: ACCOUNT_FEATURES.ALERTS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
      {
        value: 'alertsNew',
        title: 'Alerts',
        route: `${Routes.CUE_ALERTS_NEW}`,
        enableFF: ACCOUNT_FEATURES.ALERTS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: ALL_CLOUD_TYPES_EXCEPT_MULTI,
        userTypes: ALL_VALID_USERS,
      },
    ],
  },
  {
    value: 'welcome',
    title: 'Welcome',
    route: Routes.ACCOUNTS_OVERVIEW,
    icon: ICONS.objectsColumn.name,
    usersAccountTypes: [0],
    cloudTypes: [undefined, NaN],
    userTypes: [UsersType.NEW_USER],
  },
  {
    value: 'partner',
    title: 'Partner',
    icon: ICONS.userGroup.name,
    children: [
      {
        value: 'billingRulesNew',
        title: 'Billing Rules',
        route: Routes.BILLING_RULES_NEW,
        enableFF: ACCOUNT_FEATURES.BILLING_RULES_NEW,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingRules',
        title: 'Billing Rules',
        route: Routes.AWS_BILLING_RULES,
        disableFF: ACCOUNT_FEATURES.BILLING_RULES_NEW,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingStatus',
        title: 'Billing Status',
        route: Routes.AWS_CUSTOMERS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingSummary',
        title: 'Billing Summary',
        route: Routes.BILLING_SUMMARY,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
        enableFF: ACCOUNT_FEATURES.BILLING_SUMMARY,
      },
      {
        value: 'billingStatus',
        title: 'Billing Status',
        route: Routes.AZURE_CUSTOMERS,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'billingHistory',
        title: 'Billing History',
        route: Routes.CUSTOMERS_HISTORY,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'credits',
        title: 'Credits',
        route: Routes.AWS_CREDITS_NEW,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'manageCustomers',
        title: 'Manage Customers',
        route: Routes.CUSTOMERS_MANAGE,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.GCP, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'Ppreferences',
        title: 'Preferences',
        route: `${Routes.REBILLING_PREFERENCES}`,
        usersAccountTypes: ALL_USER_ACCOUNT_TYPES_EXCEPT_MULTI_CLOUD,
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
      {
        value: 'reports',
        title: 'Reports',
        route: Routes.CUSTOMERS_REPORTS,
        usersAccountTypes: [UsersAccountTypeId.REGULAR, UsersAccountTypeId.RESELLER],
        cloudTypes: [CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE],
        userTypes: [UsersType.RESELLER, UsersType.RESELLER_BUSINESS_ADMIN],
      },
    ],
  },
  {
    value: 'costGpt',
    title: 'CostGPT',
    route: Routes.COST_GPT,
    icon: ICONS.anobot.name,
    usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
    cloudTypes: ALL_CLOUD_TYPES,
    userTypes: ALL_VALID_USERS,
  },
  {
    value: 'featureFlags',
    title: 'Feature Flags',
    route: Routes.FEATURE_FLAGS,
    icon: ICONS.gear.name,
    usersAccountTypes: ALL_USER_ACCOUNT_TYPES,
    cloudTypes: ALL_CLOUD_TYPES,
    userTypes: [UsersType.SUPER_USER],
  },
];
const landingRoutes = [Routes.ACCOUNT, Routes.ACCOUNTS_OVERVIEW];

const isAvailableMenuOption = (
  menuOption,
  accountFeatures,
  cloudTypeId,
  accountTypeId,
  userTypeId,
  isKeyCloakManagement,
  permissionCategories,
  usersStore,
) => {
  // Check if top level menu is enabled - only for top level menu, the enableFF is checked without the account & user context
  if (menuOption.children && menuOption.enableFF && (accountFeatures || []).includes(menuOption.enableFF)) {
    return true;
  }
  // Check cloud type
  if (!(menuOption.cloudTypes || []).includes(cloudTypeId)) {
    return false;
  }
  // account & user context checks
  if (isKeyCloakManagement) {
    if (
      !isRouteAllowed(menuOption.route, isKeyCloakManagement, permissionCategories, usersStore.currentDisplayedUserType)
    ) {
      return false;
    }
  } else {
    // Check account type
    if (!(menuOption.usersAccountTypes || []).includes(accountTypeId)) {
      return false;
    }

    // Check user type
    if (!(menuOption.userTypes || []).includes(userTypeId)) {
      return false;
    }
  }

  // enableFF and disableFF checks
  if (menuOption.enableFF === undefined) {
    if (menuOption.disableFF && (accountFeatures || []).includes(menuOption.disableFF)) {
      return false;
    }

    if (
      menuOption.isDisabled &&
      menuOption.isDisabled({ accountTypeId, cloudTypeId, userTypeId, accountFeatures, usersStore })
    ) {
      return false;
    }
  } else if (menuOption.enableFF && !(accountFeatures || []).includes(menuOption.enableFF)) {
    return false;
  }

  return true;
};

const getPermittedLandingRoutes = (usersStore, appStore, permissionCategories) =>
  landingRoutes
    .filter((route) =>
      isRouteAllowed(route, appStore.isKeyCloakManagement, permissionCategories, usersStore.currentDisplayedUserType),
    )
    .map((route) => ({ route }));

export const getSidebarItems = (usersStore, appStore, permissionCategories) => {
  const accountTypeId = usersStore.getCurrAccountTypeId();
  const cloudTypeId = usersStore.currDispUserCloudAccountType;
  const userTypeId = usersStore.currentDisplayedUserType;
  const accountFeatures = usersStore.getCurrentDisplayedAccountFeatures(usersStore.currDispUserAccountKey);

  const visibleOptions = [];
  // go over all menu options
  sideBarMenuOptions.forEach((menuOption) => {
    if (menuOption?.disableFF && (accountFeatures || []).includes(menuOption?.disableFF)) {
      return;
    }
    if (menuOption.enableFF) {
      if (
        ((accountFeatures || []).includes(menuOption.enableFF) &&
          !isAvailableMenuOption(
            menuOption,
            accountFeatures,
            cloudTypeId,
            accountTypeId,
            userTypeId,
            appStore.isKeyCloakManagement,
            permissionCategories,
            usersStore,
          )) ||
        !(accountFeatures || []).includes(menuOption.enableFF)
      ) {
        return;
      }
    }
    // Top level only
    if (!menuOption.children) {
      if (
        isAvailableMenuOption(
          menuOption,
          accountFeatures,
          cloudTypeId,
          accountTypeId,
          userTypeId,
          appStore.isKeyCloakManagement,
          permissionCategories,
          usersStore,
        )
      ) {
        visibleOptions.push(menuOption);
      }
    } else {
      // if contains sub links, display only if there are available links
      const availableLinks = menuOption.children.filter((subOption) =>
        isAvailableMenuOption(
          subOption,
          accountFeatures,
          cloudTypeId,
          accountTypeId,
          userTypeId,
          appStore.isKeyCloakManagement,
          permissionCategories,
          usersStore,
        ),
      );
      if (availableLinks.length > 0) {
        visibleOptions.push({ ...menuOption, children: availableLinks });
      }
    }
  });

  return visibleOptions;
};

export const isMenuOptionAllowed = (route, usersStore, appStore, permissionCategories) => {
  // check if user has permissions to route based on menus and permitted routes
  const availableMenuOptions = getSidebarItems(usersStore, appStore, permissionCategories);
  const permittedRoutes = getPermittedLandingRoutes(usersStore, appStore, permissionCategories);
  const allAvailableRoutes = [...permittedRoutes, ...availableMenuOptions];
  // flat availableMenuOptions to check if route is available
  const availableMenuOptionsFlat = [];
  allAvailableRoutes.forEach((item) => {
    if (item.route) {
      availableMenuOptionsFlat.push(item.route);
    }
    if (item.children) {
      availableMenuOptionsFlat.push(...item.children.map((child) => child.route));
    }
  });
  // check if route (without query params) is in availableMenuOptionsFlat
  return availableMenuOptionsFlat.includes(route.split('?')[0]);
};
