import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { PAGINATION_DIRECTION, USER_ACTIONS } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';

import styles from './AssignRolesModal.module.scss';

const AssignRolesModal = ({ isOpen, setIsOpen, users, onAssignRoles = undefined }) => {
  const [displayedRoles, setDisplayedRoles] = useState([]);
  const [rolesToAssign, setRolesToAssign] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [search, setSearch] = useState();
  const [saveClicked] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { fetchRoles } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  const { addRolesToUsers } = useUsers(!isOpen);

  useEffect(() => {
    if (data?.roles && users?.length && !isLoading) {
      const tmp = data?.roles?.filter((role) => users.every((u) => u.roles?.every((r) => r.id !== role.identifier.id)));
      setDisplayedRoles(tmp);
    }
  }, [data, isLoading, users]);

  useEffect(() => {
    if (displayedRoles) {
      setSelectedRoles(rolesToAssign || []);
    }
  }, [displayedRoles]);

  useEffect(() => {
    if (selectedRoles?.length) {
      setRolesToAssign((prev) =>
        Array.from(new Map([...prev, ...selectedRoles].map((item) => [item.identifier.id, item])).values()),
      );
    } else {
      setRolesToAssign([]);
    }
  }, [selectedRoles]);

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    setSelectedRoles((prev) => {
      const newSelectedRoles = prev.filter((role) => !removedRoles.find((r) => r.identifier.id === role.identifier.id));
      return Array.from(
        new Map([...newSelectedRoles, ...addedRoles].map((item) => [item.identifier.id, item])).values(),
      );
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    if (onAssignRoles) {
      onAssignRoles(rolesToAssign);
    } else {
      await addRolesToUsers.mutateAsync({
        userIds: users?.length ? users?.map((u) => u.id) : undefined,
        roles: rolesToAssign?.map((r) => r.identifier),
      });
    }
    setIsOpen(false);
  };

  return (
    isOpen && (
      <CustomModal
        className={styles}
        onClose={() => {
          setIsOpen(false);
        }}
        onCloseClick={() => {
          setIsOpen(false);
        }}
        headerMode={MODAL_MODES.ADD}
        footerDisabled={isLoading && saveClicked}
        onSave={onSave}
        open={isOpen}
        automationId="assign-role-modal"
        saveTitle="Add"
        saveDisabled={false}
        title={USER_ACTIONS.ASSIGN_ROLES.label}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <AddRoles
            roles={displayedRoles}
            search={search}
            setSearch={setSearch}
            pageChanged={pageChanged}
            selectedRoles={selectedRoles}
            onRolesAddedRemoved={handleRolesAddedRemoved}
          />
        )}
      </CustomModal>
    )
  );
};

AssignRolesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  onAssignRoles: PropTypes.func,
};

AssignRolesModal.defaultProps = {
  onAssignRoles: undefined,
};

export default AssignRolesModal;
