import React, { useMemo, useState } from 'react';
import moment from 'moment/moment';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  GenerateIcon,
  ICONS,
} from '@pileus-cloud/anodot-frontend-common/dist';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import Button from 'shared/components/andtComponents/Button';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import DateFilter from 'shared/modules/dateFilter';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { isRoleIdOfAdminType } from 'users/utils/rolesUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import useRebilling from 'divisions/hooks/react-query/useRebilling';
import styles from '../CustomersTable/CustomersTable.module.scss';

const RebillingPopover = ({ row, selectedRows, roles, setSelectedRows, customers }) => {
  const { usersStore } = useRootStore();
  const lastProcessTime = DateFilter.getDate();
  const [rebillingModalOpen, setRebillingModalOpen] = useState(null);
  const [rebillingSelectedMonth, setRebillingSelectedMonth] = useState(moment(lastProcessTime).format('YYYY-MM'));
  const { triggerRebillingMutation, fetchRebillingHistory } = useRebilling();
  const { data, isLoading } = fetchRebillingHistory();
  const { statusPerCustomer = {}, statusForAll = {} } = data || {};
  const manualRebilling = statusPerCustomer[row.divisionName];
  const rebillingDisabled = useMemo(() => {
    const customersSelected = selectedRows.map((ind) => customers[ind].divisionName);
    return (
      row.divisionId === 'selected' &&
      Object.keys(statusPerCustomer).some(
        (divisionName) =>
          customersSelected.includes(divisionName) &&
          moment(statusPerCustomer[divisionName].date).add(8, 'hours').isAfter(moment()),
      )
    );
  }, [statusPerCustomer, selectedRows, row]);
  const handleTriggerRebilling = async () => {
    const customerIds =
      rebillingModalOpen === 'selected' ? selectedRows.map((ind) => customers[ind].divisionId) : [rebillingModalOpen];
    const customersNames = customers
      .filter((customer) => customerIds.includes(customer.divisionId))
      .map((customer) => customer.divisionName);
    await triggerRebillingMutation.mutateAsync({
      customers: customersNames,
      month: rebillingSelectedMonth.split('-')[1],
      year: rebillingSelectedMonth.split('-')[0],
    });
    setRebillingModalOpen(null);
    setSelectedRows([]);
  };
  const rebillingMonthsOptions = [
    moment(lastProcessTime),
    moment(lastProcessTime).add(-1, 'month'),
    moment(lastProcessTime).add(-2, 'month'),
  ].map((date) => ({
    label: date.format('MMM YYYY'),
    value: date.format('YYYY-MM'),
  }));
  const isAdmin = isRoleIdOfAdminType(roles || [], usersStore.getCurrDisplayedUserRole());
  const renderRebillingPopover = (open, id) => (
    <Popover
      open={open}
      onOpenChange={() => {
        if (open || rebillingDisabled) {
          setRebillingModalOpen(null);
        } else {
          setRebillingModalOpen(id);
        }
      }}
    >
      <PopoverTrigger as="button">
        <Tooltip
          title="Some of the selected customers were triggerred in the last 8 hours"
          disabled={!rebillingDisabled}
        >
          <span>
            <Button
              isTextButton
              disabled={rebillingDisabled}
              text="Trigger Rebilling"
              icon={() => <GenerateIcon iconName={ICONS.gear.name} />}
              onClick={() => {}}
            />
          </span>
        </Tooltip>
      </PopoverTrigger>
      <div className={styles.rebillingModal}>
        <PopoverContent className={styles.rebillingModalContent} side="bottom" sideOffset={1}>
          <div>
            <p className={styles.label}>Select Month</p>
            <SimpleSelect
              isClearable={false}
              value={rebillingSelectedMonth}
              options={rebillingMonthsOptions}
              onChange={(value) => {
                setRebillingSelectedMonth(value);
              }}
            />
            <p>
              <span>
                <InfoIcon /> Please note:
              </span>
              New data will be added as the re-billing process runs on the data consumed for that month from the payer
              account.
              <br />
              Running re-billing might cause inconsistencies between the data the user sees in the platform to the
              invoices that were already issued
            </p>
            <div className={styles.rebillingModalFooter}>
              <Button text="Cancel" isSecondary onClick={() => setRebillingModalOpen(null)} />
              <Button text="Apply" onClick={handleTriggerRebilling} isLoading={triggerRebillingMutation.isLoading} />
            </div>
          </div>
        </PopoverContent>
      </div>
    </Popover>
  );

  const isInProgress = manualRebilling && (!manualRebilling.isExecuted || manualRebilling.status === 'pending');
  const statusObj =
    manualRebilling?.status === 'pending' || manualRebilling?.status === 'none' || !manualRebilling
      ? statusForAll
      : manualRebilling;
  const isDisabled =
    (manualRebilling && moment(manualRebilling.date).add(8, 'hours').isAfter(moment())) ||
    statusObj?.status === 'pending';
  if (isLoading) {
    return null;
  }
  return (
    <ReadOnlyDisplayWrapper
      isHide={false}
      block={!isAdmin}
      category={OrganizationEntityCategory.ResellerCustomers}
      action={Action.Update}
    >
      <div className={styles.rebillingContainer}>
        {isDisabled ? (
          <div className="d-flex align-items-center">
            <Button
              className={isInProgress ? { button: styles.progressBtn } : undefined}
              text={isInProgress ? 'Rebilling in process' : 'Trigger Rebilling'}
              icon={() =>
                isInProgress ? (
                  <span className={styles.buttonLoader}>
                    <Spinner color={palette.blue[500]} />
                  </span>
                ) : (
                  <GenerateIcon iconName={ICONS.gear.name} />
                )
              }
              disabled={!isInProgress}
              isTextButton
              onClick={() => {}}
            />
            <InfoPopover>
              You will be able to trigger another re-billing within 8 hours from the last triggering.
              {manualRebilling && (
                <>
                  <br />
                  Trigger Time: {moment(manualRebilling?.date).format('MMM D YYYY | HH:mm')}
                </>
              )}
            </InfoPopover>
          </div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            {renderRebillingPopover(rebillingModalOpen === row.divisionId, row.divisionId)}
          </div>
        )}
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {statusObj?.status === 'pending' ? (
            <span className={styles.pending}>Rebilling In Process</span>
          ) : statusObj?.status === 'error' ? (
            <span className={styles.fail}>
              <GenerateIcon iconName={ICONS.xMark.name} /> Rebilling Failed
            </span>
          ) : (
            <span className={styles.success}>
              <GenerateIcon iconName={ICONS.check.name} /> Rebilling Succeeded
            </span>
          )}
          <br />
          <span className={styles.description}>
            {statusObj?.date
              ? `${statusObj?.status === 'pending' ? 'Started' : 'Last rebilling'}: ${moment(statusObj.date).format(
                  'MMM D YYYY',
                )}`
              : null}
            {statusObj?.rebillingMonth
              ? `, for ${moment()
                  .month(+statusObj?.rebillingMonth - 1)
                  .format('MMM')}`
              : ''}
          </span>
        </div>
      </div>
    </ReadOnlyDisplayWrapper>
  );
};

export default withRolesContextConsumer(RebillingPopover);
