import React, { useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useRecViews from 'recommendationsNew/hooks/react-query/useRecViews';
import styles from './viewActions.module.scss';
import { useHeatMapContext } from '../contexts/heatMapContext';

export const ViewActions = ({ view, changeViewName, setIsOpen }) => {
  const { useDeleteViewPanel, useUpdateViewPanel, useCreateViewPanel } = useRecViews();
  const { recommendationFilters } = useRecommendationsNewContext();
  const { isWasteChart, heatMapGroupByOptions } = useHeatMapContext();
  const { mutate: deleteView } = useDeleteViewPanel();
  const { mutate: updateView } = useUpdateViewPanel();
  const { mutate: createView } = useCreateViewPanel();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsOpen(false);
  };

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.actionWrapper} onClick={(e) => toggleOpen(e)}>
      <Popover open={isMenuOpen} onOpenChange={() => setIsMenuOpen(!isMenuOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={10} side="bottom" className={styles.actionPopoverContent}>
            <Button
              isTextButton
              text="Save changes"
              automationId="save-view-filters"
              className={{ button: styles.menuItem }}
              icon={() => <GenerateIcon iconName={ICONS.floppyDisk.name} className={styles.iconPadding} />}
              onClick={() => {
                updateView({ ...view, context_data: { recommendationFilters, isWasteChart, heatMapGroupByOptions } });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Rename"
              automationId="rename-view"
              className={{ button: styles.menuItem }}
              icon={() => <GenerateIcon iconName={ICONS.penToSquare.name} className={styles.iconPadding} />}
              onClick={() => {
                changeViewName({ id: view.id, name: view.view_name });
                setIsMenuOpen(false);
              }}
            />
            <Button
              isTextButton
              text={view.is_default ? 'Set Main View as default' : 'Set as Default View'}
              automationId="set-default-view"
              className={{ button: styles.menuItem }}
              icon={() => <GenerateIcon iconName={ICONS.badgeCheck.name} className={styles.iconPadding} />}
              onClick={() => {
                updateView({ ...view, is_default: !view.is_default });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Duplicate"
              automationId="duplicate-view"
              className={{ button: styles.menuItem }}
              icon={() => <GenerateIcon iconName={ICONS.copy.name} className={styles.iconPadding} />}
              onClick={() => {
                createView({
                  viewName: `Copy of ${view.view_name}`,
                  contextData: { recommendationFilters, isWasteChart, heatMapGroupByOptions },
                  isDefault: false,
                });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Delete"
              automationId="delete-view"
              className={{ button: styles.menuItem }}
              icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.iconPadding} />}
              onClick={() => {
                deleteView(view.id);
                window.history.replaceState(null, '', window.location.pathname);
                closeMenus();
              }}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};
