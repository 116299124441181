import React, { useEffect, useState } from 'react';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import EffectivePermissions from '../../../components/EffectivePermissions/EffectivePermissions';

import styles from './CreateRoleModal.module.scss';

const EffectivePermissionsStep = ({ role, setRole }) => {
  const { fetchMergedPermissionsOfRoles } = useRoles(role?.subRoles?.length > 0);
  const { data, isLoading } = fetchMergedPermissionsOfRoles({
    roleInternalNames: role?.subRoles?.map((r) => r.identifier.internalName) || [],
  });
  const [mergedPermissions, setMergedPermissions] = useState(role?.subRoles?.length > 0 ? [] : role?.permissions || []);

  const mergeNewRoleAndSubRolesPermissions = (newRolePermissions, subRolesPermissions) => {
    if (!newRolePermissions && !subRolesPermissions) {
      return;
    }

    const combinedCategories = [
      ...(newRolePermissions || []).map((rolePermission) => rolePermission.category),
      ...(subRolesPermissions || []).map((subRolePermission) => subRolePermission.category),
    ];
    const allRelevantCategories = Array.from(new Map(combinedCategories.map((item) => [item, item])).values());

    const tmpMergedPermissions = allRelevantCategories.map((category) => {
      const newRoleCategory = newRolePermissions?.find((rolePermission) => rolePermission.category === category);
      const subRoleCategory = subRolesPermissions?.find((subRolePermission) => subRolePermission.category === category);
      return {
        category,
        actionPermissions: [
          ...(subRoleCategory?.actionPermissions || []),
          ...(newRoleCategory?.actionPermissions || []),
        ],
      };
    });
    setMergedPermissions(tmpMergedPermissions);
  };

  useEffect(() => {
    if (data?.permissions) {
      mergeNewRoleAndSubRolesPermissions(role?.permissions, data?.permissions);
    }
  }, [data?.permissions]);

  const onPermissionChange = (categoryNewPermissions) => {
    const roleWithNewPermissions = { ...(role || {}), permissions: role.permissions || [] };
    const roleCategory = roleWithNewPermissions?.permissions?.find(
      (p) => p?.category === categoryNewPermissions?.category,
    );
    if (roleCategory) {
      const roleCategoryActionPermissionsIndex = roleCategory.actionPermissions?.findIndex(
        (actionPermission) => actionPermission.action === categoryNewPermissions.actionPermissions[0].action,
      );
      if (roleCategoryActionPermissionsIndex === -1) {
        roleCategory.actionPermissions.push(categoryNewPermissions.actionPermissions[0]);
      } else {
        roleCategory.actionPermissions = [
          ...roleCategory.actionPermissions.slice(0, roleCategoryActionPermissionsIndex),
          categoryNewPermissions.actionPermissions[0],
          ...roleCategory.actionPermissions.slice(roleCategoryActionPermissionsIndex + 1),
        ];
      }
    } else {
      roleWithNewPermissions.permissions.push(categoryNewPermissions);
    }
    setRole(roleWithNewPermissions);
    mergeNewRoleAndSubRolesPermissions(roleWithNewPermissions?.permissions, data?.permissions);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.effectivePermissionsStepContainer}>
      <EffectivePermissions
        permissions={mergedPermissions}
        roleInternalName={role?.subRoles?.[0]?.identifier.internalName}
        permissionChanged={onPermissionChange}
      />
    </div>
  );
};

export default EffectivePermissionsStep;
