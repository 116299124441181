import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import styles from '../billingSummary.module.scss';
import CustomersHistoryTable from './Table.tsx';
import HistoryTableFilters from './HistoryTableFilters.tsx';
import useBillingInfo from '../../../../hooks/react-query/useBillingInfo';
import { getInitialDate } from '../../../../utils';
import { LINES_IN_PAGE, prepareServerExportRows } from '../../utils.ts';
import { BillingHistoryRow } from '../../types.ts';
import useExportRows from '../useExportRows.ts';

const currentMonth = new Date().getMonth() + 1;

const CustomersHistory = () => {
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState<Date>(getInitialDate().startDate);
  const [endDate, setEndDate] = useState(getInitialDate().endDate);
  const [showCurrentMonth, setShowCurrentMonth] = useState(true);
  const [showHistory, setShowHistory] = useState(true);
  const { usersStore } = useRootStore();
  const { NewTableWrapper } = useTable();

  useEffect(() => {
    setSelectedRowsIndexes([]);
  }, [showHistory, showCurrentMonth, startDate, endDate]);

  const { fetchBillingHistory, fetchResellerCustomerInvoiceData } = useBillingInfo();
  const { data: historyData = [], isLoading } = fetchBillingHistory({
    startDate,
    endDate,
    cloudTypeId: usersStore.currDispUserCloudAccountType,
  });

  const { exportRows } = useExportRows('reseller-customers-invoices', fetchResellerCustomerInvoiceData);

  const onDatePickerChange = useCallback((startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const filteredHistoryData = useMemo(
    () =>
      historyData.filter(
        (row: BillingHistoryRow) =>
          (searchText.length >= 2 ? row.customerName.toLowerCase().includes(searchText.toLowerCase()) : true) &&
          (row.month < currentMonth ? showHistory : showCurrentMonth),
      ),
    [historyData, searchText, showHistory, showCurrentMonth],
  );

  if (isLoading) {
    return <Spinner />;
  }

  const rowsWithPagination = filteredHistoryData.slice(LINES_IN_PAGE * currentPage, LINES_IN_PAGE * (currentPage + 1));

  const setSelectedRowIndexesWithDisabled = (selectedIndexes: number[]) => {
    const filteredIndexes = !showCurrentMonth
      ? selectedIndexes
      : selectedIndexes.filter((i) => rowsWithPagination[i].month < currentMonth);
    setSelectedRowsIndexes(filteredIndexes);
  };
  const selectedRows = rowsWithPagination.filter((row, index) => selectedRowsIndexes.includes(index));
  const onInvoicesDownload = (type: 'csv' | 'pdf') => {
    exportRows({ rows: prepareServerExportRows(selectedRows), type });
  };

  return (
    <div className={styles.billingHistory}>
      <NewTableWrapper className={selectedRowsIndexes?.length > 0 && 'custom-pager'}>
        <HistoryTableFilters
          searchText={searchText}
          setSearchText={setSearchText as () => void}
          isExpanded={isAllExpanded}
          setIsExpanded={setIsAllExpanded}
          onPageChange={(newPageIndex) => setCurrentPage(newPageIndex)}
          currentPage={currentPage}
          totalPages={Math.ceil(filteredHistoryData.length / LINES_IN_PAGE)}
          headerText={`Billing Status(${historyData.length})`}
          startDate={startDate}
          endDate={endDate}
          onDateChange={onDatePickerChange}
          tableData={historyData}
          showCurrentMonth={showCurrentMonth}
          setShowCurrentMonth={setShowCurrentMonth}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          isInvoiceDownloadDisabled={!selectedRowsIndexes.length}
          onInvoicesDownload={onInvoicesDownload}
        />
        <div>
          <CustomersHistoryTable
            rows={rowsWithPagination}
            selectedRowsIndexes={selectedRowsIndexes}
            setSelectedRowsIndexes={setSelectedRowIndexesWithDisabled}
            isAzure={usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE}
            isAllExpanded={isAllExpanded}
            timeRange={[startDate, endDate]}
            showSelectAll={!showCurrentMonth}
          />
        </div>
      </NewTableWrapper>
    </div>
  );
};

export default CustomersHistory;
