import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  CustomClickableFormatter,
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  GET_USERS_QUERY_PARAMS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  ROLE_USERS_COLUMNS,
  TAB_PATHS,
} from '../../consts';
import { ReactComponent as NoRoles } from '../img/no-roles.svg';
import RemoveButton from '../../components/RemoveButton';
import UserRoles from '../../Users/usersTableComponents/UserRoles';
import UserStatusSwitchButton from '../../components/UserStatusSwitchButton';

import styles from './RoleTab.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoRoles />
      <span className={styles.mainText}>No Roles for this user</span>
    </div>
  );
};

const onUserNameClick = (row) => {
  const currentUrl = new URL(window.location.href);
  if (currentUrl.pathname.endsWith(`/${TAB_PATHS.ROLES}`)) {
    currentUrl.pathname = currentUrl.pathname.replace(`/${TAB_PATHS.ROLES}`, `/${TAB_PATHS.USERS}`);
  } else {
    currentUrl.pathname = `${currentUrl.pathname}/${TAB_PATHS.USERS}`;
  }
  currentUrl.searchParams.set(GET_USERS_QUERY_PARAMS.SEARCH.name, row?.username);
  window.open(currentUrl.toString(), '_blank');
};

const RoleUsersTabTable = ({ roleUsers, selectedUserRoles, setSelectedUserRoles, removeUserClicked }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_USERS_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  return (
    roleUsers && (
      <Grid rows={roleUsers} columns={getColumns(ROLE_USERS_COLUMNS)} automationId="role-users-tab-list">
        <SortingState
          columnExtensions={getSortingColumns(ROLE_USERS_COLUMNS)}
          defaultSorting={getDefaultSorting(ROLE_USERS_COLUMNS)}
        />
        <SelectionState selection={selectedUserRoles} onSelectionChange={setSelectedUserRoles} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.USER_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomClickableFormatter {...props} onClick={() => onUserNameClick(props?.row)} />
          )}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.FIRST_NAME.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.LAST_NAME.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.ROLES.columnName]}
          formatterComponent={UserRoles}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip value={moment(props.createdAt).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.LAST_LOGIN.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip value={moment(props.lastLogin).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.USER_STATUS.columnName]}
          formatterComponent={(props) => <UserStatusSwitchButton row={props.row} isViewOnly />}
        />
        <DataTypeProvider
          for={[ROLE_USERS_COLUMNS.REMOVE_USER_ROLE.columnName]}
          formatterComponent={(props) => (
            <RemoveButton props={props} removeClicked={() => removeUserClicked?.(props.row)} />
          )}
        />
        <TableWrapper virtual noDataCellComponent={UserRolesNoData} height="auto" />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

RoleUsersTabTable.propTypes = {
  roleUsers: PropTypes.array.isRequired,
};

export default RoleUsersTabTable;
