import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import toast from 'shared/components/andtComponents/Toast';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { anomalyUserStatusProvider } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './AnomalyUserStatus.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
};

const AnomalyUserStatus = ({ row }) => {
  const [userStatus, setUserStatus] = useState(row.userStatuses?.slice(-1)[0] || null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { usersStore } = useRootStore();

  const anomalyUserStatusHook = anomalyUserStatusProvider(row.uuid);
  const { mutateAsync: addUserStatus } = anomalyUserStatusHook.addUserStatus({
    onError: () => {
      toast.error('Failed to add user status');
    },
  });

  const addNewUserStatus = async (status) => {
    const userStatusToAdd = {
      status: userStatus?.status === status ? 'DEFAULT' : status,
      updatedBy: usersStore.getCurrUserName,
    };
    setUserStatus(userStatusToAdd);
    setIsExpanded(false);
    await addUserStatus(userStatusToAdd);
  };

  const userStatusToIcon = ({ status = 'DEFAULT' }) =>
    ({
      DEFAULT: ICONS.clipboard.name,
      RESOLVED: ICONS.clipboardCheck.name,
      ACK: ICONS.userCheck.name,
    }[status]);

  const userStatusTooltipText = {
    DEFAULT: 'User Status',
    RESOLVED: `Resolved by:\n${userStatus?.updatedBy}`,
    ACK: `Acknowledged by:\n${userStatus?.updatedBy}`,
  }[userStatus?.status || 'DEFAULT'];

  const renderOption = (status) => (
    <div
      className={`${styles.userStatusOption} ${userStatus?.status === status && styles.selected}`}
      onClick={() => {
        addNewUserStatus(status);
      }}
    >
      <div className={styles.iconContainer}>
        <GenerateIcon
          iconName={userStatusToIcon({
            status,
          })}
          className={styles[status]}
        />
      </div>
      <div>
        <h4>
          {userStatus?.status === status ? 'Unmark' : 'Mark'} as
          <span>{status === 'RESOLVED' ? ' Resolved' : ' Acknowledged'}</span>
        </h4>
        <p>
          {status === 'RESOLVED'
            ? 'Mark the anomaly "resolved" to track your activity. This action will not close the anomaly.'
            : 'Acknowledge the anomaly as an issue you are further investigating.'}
        </p>
      </div>
    </div>
  );

  return (
    <Tooltip title={userStatusTooltipText} placement="top" disabled={isExpanded}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <GenerateIcon
          iconName={userStatusToIcon({ status: userStatus?.status })}
          className={styles[userStatus?.status]}
          onClick={() => setIsExpanded(!isExpanded)}
        />
        {isExpanded && (
          <>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Organization} action={Action.Update}>
              <div className={styles.userStatusModal}>
                {renderOption('RESOLVED')}
                {renderOption('ACK')}
              </div>
            </ReadOnlyDisplayWrapper>
            <div className={styles.closeWrapper} onClick={() => setIsExpanded(false)} />
          </>
        )}
      </div>
    </Tooltip>
  );
};

AnomalyUserStatus.propTypes = propTypes;
export default AnomalyUserStatus;
