import React from 'react';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import EffectivePermissions from '../../components/EffectivePermissions/EffectivePermissions';

const EffectivePermissionsTab = ({ row }) => {
  const { fetchRolePermissions, setRolePermissions } = useRoles();
  const { data, isLoading } = fetchRolePermissions(row?.identifier?.internalName);

  const onPermissionChange = (categoryNewPermissions) => {
    setRolePermissions.mutate({
      roleInternalName: row?.identifier?.internalName,
      roleCategoryPermissions: [categoryNewPermissions],
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="position-relative">
          <Spinner />
        </div>
      ) : (
        <EffectivePermissions
          roleInternalName={row?.identifier.internalName}
          permissions={data?.permissions}
          permissionChanged={onPermissionChange}
        />
      )}
    </>
  );
};

export default EffectivePermissionsTab;
