import React from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Eye from 'mdi-react/EyeIcon';
import PencilIcon from 'shared/img/icons/PencilIcon';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { IconButton } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { PANEL_COMPONENT_TYPES } from '../../CustomDashboard/constants/customDashboardConstants';
import { prepareRecommendationsFiltersMap } from '../../../../recommendations/utils/recommUtils';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

class RecActionButton extends React.Component {
  state = {};

  handleMenuItemClick = (panel, handler) => {
    handler(panel);
  };

  isDeletePanelAvailable = (type, cloudAccountId) => {
    const { currDispUserCloudAccId } = this.props;
    return cloudAccountId === currDispUserCloudAccId;
  };

  render() {
    const {
      panel,
      panelHandlers,
      isMagnifierAvailable,
      getMagnifierPath,
      history,
      customDashboardModel,
    } = this.props;
    const isDeleteAvailable = this.isDeletePanelAvailable(panel.type, panel.accountId);
    const { addPanelToDashboard, hidePanel } = panelHandlers;
    return (
      <i>
        <Row>
          <Col className="flex-grow-0 ps-2 pe-2">
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
              <IconButton
                style={{
                  ...buttonContainerStyle,
                  color: isDeleteAvailable ? '#70bbfd' : '#bebebe',
                }}
                onClick={() => this.handleMenuItemClick(panel, hidePanel)}
                disabled={!isDeleteAvailable}
                size="large">
                <Delete />
              </IconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col className="flex-grow-0 ps-2 pe-2">
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
              <IconButton
                style={buttonContainerStyle}
                onClick={() => this.handleMenuItemClick(panel, addPanelToDashboard)}
                size="large">
                <PencilIcon className="svg-pencil-icon panels-svg-pencil-icon" />
              </IconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col className="flex-grow-0 ps-2 pe-2">
            <IconButton
              style={{
                ...buttonContainerStyle,
                color: isMagnifierAvailable ? '#70bbfd' : '#bebebe',
              }}
              onClick={async () => {
                const activePanel = await customDashboardModel.getPanelById(panel.uuid);
                const { state, type, routeParams } = activePanel;
                history.push({
                  pathname: `${getMagnifierPath(type, (state || {}).isK8S)}`,
                  source: {
                    id: activePanel.uuid,
                    name: activePanel.name,
                    isFromCustomDashboard: true,
                    isDisplayTable: type.includes('table'),
                    isPieChart: type.includes('pie'),
                    isLineChart: (state || {}).isLineChart,
                    isAreaChart: (state || {}).isAreaChart,
                    startDate: activePanel.startDate,
                    endDate: activePanel.endDate,
                    state: state
                      ? {
                          ...state,
                          selectedGranLevel: (routeParams || {}).periodGranLevel || state.selectedGranLevel,
                          currPeriodGranLevel: (routeParams || {}).periodGranLevel || state.currPeriodGranLevel,
                        }
                      : null,
                    routeParams,
                    filtersMap: [
                      PANEL_COMPONENT_TYPES.RECOMM_CLOUD_WASTE,
                      PANEL_COMPONENT_TYPES.RECOMM_SUMMERY_TABLE,
                    ].includes(type)
                      ? prepareRecommendationsFiltersMap((routeParams || {}).filterSelectedValues)
                      : (routeParams || {}).filtersMap,
                  },
                });
              }}
              disabled={!isMagnifierAvailable}
              size="large">
              <Eye />
            </IconButton>
          </Col>
        </Row>
      </i>
    );
  }
}

RecActionButton.propTypes = {
  panelHandlers: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  currDispUserCloudAccId: PropTypes.string.isRequired,
  isMagnifierAvailable: PropTypes.bool.isRequired,
  getMagnifierPath: PropTypes.func.isRequired,
  customDashboardModel: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const ObserverRecActionButton = withRouter(observer(RecActionButton));
export default ObserverRecActionButton;
