import React, { useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { CSVLink } from 'react-csv';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { objAwsRegionNames } from 'shared/constants/awsConstants';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import FilesTable from './components/FilesTable';
import AccountTagsUploadModal from './components/AccountTagsUploadModal';
import { useAccountTags } from '../../hooks/react-query/useAccountTags';
import styles from './AccountTags.module.scss';

const csvExampleData = [
  ['linked_account_id', 'company', 'division', 'department', 'region'],
  ['1369484921', 'Company A', 'Technology', 'R&D', 'US'],
  ['1369484922', 'Company A', 'Sales', 'Marketing', 'EU'],
  ['1369484923', 'Company A', 'Finance', 'Budget', 'EU'],
];

const getFirstAWSAccount = (current, list) => {
  if (current.cloudTypeId === CLOUD_TYPE_IDS.AWS) {
    return current;
  }
  return list.find((a) => a.cloudTypeId === CLOUD_TYPE_IDS.AWS);
};

const AccountTagsPage = () => {
  const { usersStore } = useRootStore();
  const accountsList = usersStore.getAllCurrDisplayedUserAccounts();
  const [accountId, setAccountId] = useState(
    getFirstAWSAccount(usersStore.getCurrDisplayedAccount, accountsList)?.accountId,
  );
  const [bucketName, setBucketName] = useState('');
  const [bucketRegion, setBucketRegion] = useState('us-east-1');
  const [fileName, setFileName] = useState('');
  const {
    fetchAccountTagsData,
    fetchAccountTagsMetadata,
    useDeleteAccountTagsFile,
    useUpdateAccountTagInfo,
    useUploadAccountTagsFile,
    uploadAccountTagsFileFromS3,
  } = useAccountTags();
  const { data: accountTagsData, isLoading: accountTagsDataLoading } = fetchAccountTagsData();
  const { isLoading: accountTagsMetadataLoading, data: accountTagsMetadata } = fetchAccountTagsMetadata();
  const handleDeleteFile = useDeleteAccountTagsFile();
  const handleUpdateFile = useUpdateAccountTagInfo();
  const handleUploadFromS3File = uploadAccountTagsFileFromS3();
  const handleUploadFile = useUploadAccountTagsFile();
  const [fileDataOpen, setFileDataOpen] = useState(null);
  const prepareDataForTable = (viewedFileData) => {
    const preparedFileDataToModalTable = [...viewedFileData];
    const columnTitle = viewedFileData.reduce((acc, curr) => {
      const { uuid, upload_date: uploadDate, uuid_linked_account_id: linkedAccountId, ...rest } = curr;
      Object.keys(rest).forEach((key) => acc.add(key));
      return acc;
    }, new Set());
    const modalTableColumns = Array.from(columnTitle).map((input) => ({ name: input, title: input }));
    return { preparedFileDataToModalTable, modalTableColumns };
  };
  const viewFileDataHandler = () => {
    const viewedFileName = accountTagsMetadata[0]?.fileName;
    const viewedFileData = Array.from(accountTagsData.values());
    if (isEmptyArray(viewedFileData)) {
      toast.error("There was an error retrieving file's data");
      return;
    }
    const { preparedFileDataToModalTable, modalTableColumns } = prepareDataForTable(viewedFileData);
    setFileDataOpen({
      viewedFileName,
      viewedFileData: preparedFileDataToModalTable,
      modalTableColumns,
    });
  };

  const readFile = (file) => {
    const { type, name } = file;
    const reader = new FileReader();
    if (file.size > 10485760) {
      toast.error('File is too big, please consider uploading it through the S3 bucket.');
      return;
    }
    try {
      reader.onabort = () => toast.error('Error reading the file');
      reader.onerror = () => toast.error('Error reading the file');
      reader.onload = async () => {
        try {
          const content = reader.result;
          await handleUploadFile.mutateAsync({ metaData: { type, name }, content });
        } catch (e) {
          toast.error('Error reading the file');
        }
      };
      reader.readAsText(file);
    } catch (error) {
      toast.error('Error reading the file');
    }
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length) {
      toast.error('Wrong file format, please use CSV files only');
      return;
    }
    readFile(acceptedFiles[0]);
  };
  const renderDragZoneOrFileTable = (files) => {
    if (files && files.length) {
      return (
        <FilesTable
          uploadedFiles={files}
          deleteHandler={(file) => handleDeleteFile.mutateAsync(file.id)}
          viewFileDataHandler={viewFileDataHandler}
        />
      );
    }
    return (
      <ReadOnlyDisplayWrapper action={CategoryAction.Create} category={OrganizationEntityCategory.CostAllocation}>
        <div className={styles.container}>
          <div>
            <p className={styles.label}>Upload file directly (up to 10MB)</p>
            <Dropzone
              disabled={handleUploadFromS3File.isLoading || handleUploadFile.isLoading}
              accept=".csv"
              onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className={styles.dropzone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag & Drop a CSV file, or click to select a file</p>
                    <p>Up to 10MB</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          {accountId && (
            <div>
              <p className={styles.label}>Upload file from your S3 bucket</p>
              <div className={styles.bucketConfig}>
                <p>Account:</p>
                <SimpleSelect
                  className={styles.input}
                  isClearable={false}
                  placeholder="Select account"
                  value={accountId}
                  options={accountsList
                    .filter((a) => a.cloudTypeId === CLOUD_TYPE_IDS.AWS)
                    .map((a) => ({ label: `${a.accountName} (${a.accountId})`, value: a.accountId }))}
                  onChange={(val) => setAccountId(val)}
                />
                <p>File Name:</p>
                <Input
                  className={styles.input}
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                  placeholder="e.g. enrichment_tags.csv"
                  type="text"
                />
                <p>Bucket Name:</p>
                <Input
                  className={styles.input}
                  value={bucketName}
                  onChange={(e) => {
                    setBucketName(e.target.value);
                  }}
                  placeholder="e.g. tags-storage"
                  type="text"
                />
                <p>Bucket Region:</p>
                <SimpleSelect
                  className={styles.input}
                  isClearable={false}
                  placeholder="Select region"
                  value={bucketRegion}
                  options={Object.values(objAwsRegionNames).map((a) => ({ label: a, value: a }))}
                  onChange={(val) => setBucketRegion(val)}
                />
                <p className={styles.note}>
                  <span>Note</span>
                  The bucket permissions should be similar to these given for the CUR bucket during the onboarding
                  stage.
                </p>
                <div className={styles.submitButton}>
                  <Button
                    onClick={() => handleUploadFromS3File.mutate({ fileName, bucketRegion, bucketName, accountId })}
                    text="Upload from S3"
                    isLoading={handleUploadFromS3File.isLoading}
                    disabled={handleUploadFile.isLoading || !bucketName || !bucketRegion || !fileName}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ReadOnlyDisplayWrapper>
    );
  };
  return (
    <Container>
      <PageHeader barIcons title={PageNames.ACCOUNT_TAGS} />
      <Card>
        <p>
          Upload a CSV file for enriched info on linked accounts in reports and Cost & Usage explorer (including
          Kubernetes).
          <span className={styles.buttonLink}>
            <CSVLink data={csvExampleData} filename="enrichment_tags_data_example.csv">
              <span>Download CSV example file</span>
            </CSVLink>
          </span>
        </p>
        <CardBody style={{ minHeight: '300px' }}>
          {accountTagsDataLoading || accountTagsMetadataLoading ? (
            <Spinner />
          ) : (
            renderDragZoneOrFileTable(accountTagsMetadata)
          )}
          <AccountTagsUploadModal
            toggle={() => setFileDataOpen(null)}
            isOpen={!!fileDataOpen}
            title={fileDataOpen?.viewedFileName}
            data={fileDataOpen?.viewedFileData}
            columns={fileDataOpen?.modalTableColumns}
            handleUpdateFile={handleUpdateFile.mutateAsync}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default AccountTagsPage;
