import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

import classes from './cloudWasteChartTooltip.module.scss';

export const CloudWasteChartTooltip = ({ payload }) => {
  const { currencySymbol } = useUserSettingsContext();
  const uniqueArray = payload
    ?.filter((item, index) => payload.findIndex((t) => t.dataKey === item.dataKey) === index)
    .sort((a, b) => b.value - a.value);

  const overWaste = uniqueArray?.find((entry) => entry.dataKey === 'overWaste');
  const targetWaste = uniqueArray?.find((entry) => entry.dataKey === 'targetWaste');
  const currentCost = uniqueArray?.find((entry) => entry.dataKey === 'currentCost');

  const potentialSavingsAmount = parseFloat(overWaste?.value) + parseFloat(targetWaste?.value);
  const formattedPotentialSavingsAmount = potentialSavingsAmount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
  let potentialSavings = ` ${currencySymbol} ${formattedPotentialSavingsAmount}`;
  if (currentCost?.value) {
    potentialSavings += ` (${((potentialSavingsAmount * 100) / parseFloat(currentCost?.value)).toFixed(2)}%)`;
  }

  if (uniqueArray?.length) {
    return (
      <div className={classes.customizedTooltip}>
        <div className={classes.tooltipTitleContainer}>
          <GenerateIcon iconName={ICONS.calendarDay.name} />
          <div className={classes.tooltipTitle}>{payload[0].payload.fullDate}</div>
        </div>
        <div className={classes.tooltipDataContainer}>
          {uniqueArray?.map((entry) => (
            <div className={classes.tooltipDataPair}>
              <div className={classes.tooltipDataCircle} style={{ backgroundColor: entry.color }} />
              <div className={classes.tooltipData}>
                {`${entry.name}: `}
                <span className={classes.tooltipDataValue}>
                  {`$ ${entry.value.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}`}
                </span>
              </div>
            </div>
          ))}
          <div className={classes.tooltipPotentialSavingsContainer}>
            <div className={classes.tooltipPotentialSavings}>
              <div className={classes.potentialSavingsTitle}>
                Potential Savings:
                <span className={classes.tooltipDataValue}>{potentialSavings}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
