import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common/dist';
import { CategoryAction, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { Routes } from 'shared/constants/routes';
import { cloudTypeToSimpleIcon } from 'shared/constants/appConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import ProviderPopover from './ProviderPopover';
import styles from './CloudProviderButton.module.scss';

const PROVIDER_TITLE = {
  [CLOUD_TYPE_IDS.AWS]: 'AWS',
  [CLOUD_TYPE_IDS.AZURE]: 'Azure',
  [CLOUD_TYPE_IDS.GCP]: 'GCP',
};

const PROVIDER_LINK = {
  [CLOUD_TYPE_IDS.AWS]: Routes.ONBOARDING_AWS,
  [CLOUD_TYPE_IDS.AZURE]: Routes.ONBOARDING_AZURE,
  [CLOUD_TYPE_IDS.GCP]: Routes.ONBOARDING_GCP,
};

const CloudProviderButton =
  ({ type }) =>
  // eslint-disable-next-line react/prop-types
  ({ usersStore, history }) => {
    const [open, setOpen] = useState(false);
    const Logo = cloudTypeToSimpleIcon[type];
    const title = PROVIDER_TITLE[type];
    const PopoverBody = ProviderPopover[type];
    const link = PROVIDER_LINK[type];
    if (type === CLOUD_TYPE_IDS.AZURE) {
      return (
        <div className={styles.popoverWrapper}>
          <div className={styles.wrapper}>
            <div
              className={styles.container}
              onClick={() => {
                if (!usersStore.isHasPermission(HierarchicalEntityCategory.Accounts, CategoryAction.Create)) {
                  return;
                }
                setOpen(true);
              }}
            >
              <Logo />
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p className={styles.title} onClick={() => setOpen(true)}>
              {title} <InfoIcon className={styles.infoIcon} />
            </p>
          </div>
          {open && (
            <CustomModal footerDisabled title="" onClose={() => setOpen(false)} open>
              <PopoverBody onClose={() => setOpen(false)} link={link} />
            </CustomModal>
          )}
        </div>
      );
    }
    return (
      <div className={styles.popoverWrapper}>
        <Popover
          open={open}
          onOpenChange={() => {
            if (!usersStore.isHasPermission(HierarchicalEntityCategory.Accounts, CategoryAction.Create)) {
              return;
            }
            setOpen(!open);
          }}
        >
          <div className={styles.wrapper}>
            <div
              className={styles.container}
              onClick={() => {
                if (!usersStore.isHasPermission(HierarchicalEntityCategory.Accounts, CategoryAction.Create)) {
                  return;
                }
                history.push(link);
              }}
            >
              <Logo />
            </div>
            <PopoverTrigger>
              <p className={styles.title}>
                {title} <InfoIcon className={styles.infoIcon} />
              </p>
            </PopoverTrigger>
          </div>
          <PopoverContent className={styles.popoverContent}>
            <PopoverBody onClose={() => setOpen(false)} link={link} />
          </PopoverContent>
        </Popover>
      </div>
    );
  };

CloudProviderButton.propTypes = {
  type: PropTypes.oneOf(Object.values(CLOUD_TYPE_IDS)).isRequired,
  featureFlag: PropTypes.string,
  usersStore: PropTypes.object.isRequired,
};

CloudProviderButton.defaultProps = {
  featureFlag: null,
};

export default {
  AWS: withRouter(CloudProviderButton({ type: CLOUD_TYPE_IDS.AWS })),
  GCP: withRouter(CloudProviderButton({ type: CLOUD_TYPE_IDS.GCP })),
  AZURE: withRouter(CloudProviderButton({ type: CLOUD_TYPE_IDS.AZURE })),
};
