import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { FILTERS, STATUS_FILTER_PRESETS } from 'recommendationsNew/consts';
import { CustomUserStatus } from './customUserStatus';
import { StatusOptionTemplate } from './statusOptionTemplate';
import HeatMapActionsContext from '../../contexts/heatMapActionsContext';
import {
  ActualSavingsStatusFilterOption,
  ExcludedStatusFilterOption,
  PotentialSavingsStatusFilterOption,
  UserActionsStatusFilterOption,
} from './statusFilterOptions';
import { useHeatMapContext } from '../../contexts/heatMapContext';

import classes from './statusFilter.module.scss';

export const StatusFilter = () => {
  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useRecommendationsNewContext();
  const { isStatusFilterOpen, setIsStatusFilterOpen } = useContext(HeatMapActionsContext);
  const { isWasteChart } = useHeatMapContext();

  const expandableRef = useRef(null);

  const getFilterContextStatus = () => ({
    [FILTERS.STATUS_FILTER.id]: filtersContext?.[FILTERS.STATUS_FILTER.id],
    [FILTERS.IS_OPEN.id]: filtersContext?.[FILTERS.IS_OPEN.id],
    [FILTERS.USER_STATUS.id]: filtersContext?.[FILTERS.USER_STATUS.id],
  });

  const [localFiltersCopy, setLocalFiltersCopy] = useState(getFilterContextStatus());

  useEffect(() => {
    if (!isStatusFilterOpen) {
      setLocalFiltersCopy(getFilterContextStatus());
    }
  }, [filtersContext]);

  const statusFilterTitle = useMemo(() => {
    const statusFilterLabel = Object.values(STATUS_FILTER_PRESETS).find(
      (option) => option.id === localFiltersCopy[FILTERS.STATUS_FILTER.id],
    )?.displayName;
    return `Status${statusFilterLabel ? ` (${statusFilterLabel})` : ''} `;
  }, [localFiltersCopy]);

  const onExpand = (option) => {
    // Scroll down to the end of the Status filter option, so that the Custom option will be visible
    if (option.id === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id) {
      setTimeout(() => {
        if (expandableRef.current) {
          expandableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 200);
    }
  };

  const onOpenDateRangeFilterChange = () => {
    const newIsOpenState = !isStatusFilterOpen;
    setIsStatusFilterOpen(newIsOpenState);

    if (newIsOpenState === false) {
      setFiltersContext({
        ...filtersContext,
        [FILTERS.STATUS_FILTER.id]: localFiltersCopy[FILTERS.STATUS_FILTER.id],
        [FILTERS.IS_OPEN.id]: localFiltersCopy[FILTERS.IS_OPEN.id],
        [FILTERS.USER_STATUS.id]: localFiltersCopy[FILTERS.USER_STATUS.id],
      });
    }

    if (newIsOpenState === true) {
      onExpand({ id: localFiltersCopy[FILTERS.STATUS_FILTER.id] });
    }
  };

  const setStatusFilterOption = (option) => {
    onExpand(option);

    setLocalFiltersCopy({
      ...localFiltersCopy,
      [FILTERS.STATUS_FILTER.id]: option.id,
    });
  };

  const onCustomFiltersChange = (newFilters) => {
    setLocalFiltersCopy({
      ...localFiltersCopy,
      [FILTERS.IS_OPEN.id]: newFilters?.[FILTERS.IS_OPEN.id],
      [FILTERS.USER_STATUS.id]: { ...newFilters?.[FILTERS.USER_STATUS.id] },
    });
  };

  return (
    <div
      className={`${classes.statusFilterContainer} ${isStatusFilterOpen ? classes.opened : ''}`}
      automation-id="user-status-filter"
    >
      <Popover open={isStatusFilterOpen} onOpenChange={onOpenDateRangeFilterChange}>
        <PopoverTrigger className={classes.popoverTrigger}>
          <Tooltip title={statusFilterTitle} placement="top">
            <div className={classes.statusTitleContainer} automation-id="status-filter-button">
              <span className={classes.title}>{statusFilterTitle}</span>
              <GenerateIcon iconName={ICONS.caretDown.name} className={classes.arrowDown} />
            </div>
          </Tooltip>
        </PopoverTrigger>

        <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
          <div ref={expandableRef}>
            <div className={classes.statusOptionsContainer}>
              <StatusOptionTemplate
                option={STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS}
                isSelected={localFiltersCopy[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id}
                hideSelection={isWasteChart}
                onOptionSelect={setStatusFilterOption}
              >
                <PotentialSavingsStatusFilterOption />
              </StatusOptionTemplate>
            </div>
            {isWasteChart ? null : (
              <>
                <div className={classes.statusOptionsContainer}>
                  <StatusOptionTemplate
                    option={STATUS_FILTER_PRESETS.ACTUAL_SAVINGS}
                    isSelected={localFiltersCopy[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id}
                    onOptionSelect={setStatusFilterOption}
                  >
                    <ActualSavingsStatusFilterOption />
                  </StatusOptionTemplate>
                </div>
                <div className={classes.statusOptionsContainer}>
                  <StatusOptionTemplate
                    option={STATUS_FILTER_PRESETS.EXCLUDED}
                    isSelected={localFiltersCopy[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.EXCLUDED.id}
                    onOptionSelect={setStatusFilterOption}
                  >
                    <ExcludedStatusFilterOption />
                  </StatusOptionTemplate>
                </div>
                <div className={classes.statusOptionsContainer}>
                  <StatusOptionTemplate
                    option={STATUS_FILTER_PRESETS.USER_ACTIONS}
                    isSelected={localFiltersCopy[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.USER_ACTIONS.id}
                    onOptionSelect={setStatusFilterOption}
                  >
                    <UserActionsStatusFilterOption />
                  </StatusOptionTemplate>
                </div>
                <div className={classes.statusOptionsContainer}>
                  <StatusOptionTemplate
                    option={STATUS_FILTER_PRESETS.CUSTOM_SETTINGS}
                    isExpandable
                    isSelected={localFiltersCopy[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id}
                    onOptionSelect={setStatusFilterOption}
                  >
                    <CustomUserStatus filter={localFiltersCopy} onFiltersChange={onCustomFiltersChange} />
                  </StatusOptionTemplate>
                </div>
              </>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
