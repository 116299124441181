import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { Routes } from 'shared/constants/routes';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { ReactComponent as EyeIcon } from 'shared/img/icons/eye.svg';
import IconButton from '@mui/material/IconButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import SaveShareSettingsModal, { ACCESS_LEVELS, AccessLevelOptions } from 'shared/components/SaveShareSettingsModal';
import { shareSettingsItemTypes } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@pileus-cloud/anodot-frontend-common/dist';
import { ReactComponent as StarIcon } from 'shared/img/icons/star.svg';
import { ReactComponent as EmptyListIcon } from 'shared/img/icons/empty-list.svg';
import { standardDateFormat } from 'shared/utils/dateUtil';
import toast from 'shared/components/andtComponents/Toast';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { useReports } from 'usage/hooks/react-query/useReports';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import { ReportSendFrequency, USER_SAVED_REPORT_TYPES } from 'usage/constants/costAndUsageConstants';
import useRoles from 'users/hooks/react-query/useRoles';
import { isRoleIdOfAdminType } from 'users/utils/rolesUtil';
import CustomDashboardActionButton from './CustomDashboardActionButton';
import CustomDashboardTemplateActionButton from './CustomDashboardTemplateActionButton';
import * as Constants from '../constants/customDashboardConstants';
import EditOrCloneCustomDashboardModal from './EditOrCloneCustomDashboardModal';
import { prepareFrequencyStartDate } from '../helpers/scheduledReportHelper';
import ReportsHelperMethods from '../../CostAndUsageExplorer/helpers/reportsHelperMethods';
import styles from './CustomDashboardsTable.module.scss';

const filterBySearch = (data, searchStr) =>
  data.filter((row) => !searchStr || (row.name || '').toLowerCase().includes(searchStr.toLowerCase()));

// eslint-disable-next-line react/prop-types
const TableRow = ({ row, ...restProps }) => (
  <Table.Row
    {...restProps}
    /* eslint-disable-next-line react/prop-types */
    className={row.isDefault ? styles.defaultDashboardRow : ''}
  />
);

const TABS = {
  DASHBOARDS: 'DASHBOARDS',
  TEMPLATES: 'TEMPLATES',
};

const CustomDashboardsTable = ({ usageStore, usersStore, invoiceStore, history, searchStr }) => {
  const [dashboardSelectedForDelete, setDashboardSelectedForDelete] = useState(null);
  const [templateSelectedForDelete, setTemplateSelectedForDelete] = useState(null);
  const [selectedDashboardForEdit, setSelectedDashboardForEdit] = useState(null);
  const [templateSelectedForEdit, setTemplateSelectedForEdit] = useState(null);
  const [templateSelectedForCloneToDashboards, setTemplateSelectedForCloneToDashboards] = useState(null);
  const [selectedDashboardForClone, setSelectedDashboardForClone] = useState(null);
  const [selectedDashboardForReport, setSelectedDashboardForReport] = useState(null);
  const [shareSettingsModalOpen, setShareSettingsModalOpen] = useState(null);
  const [dashboardUpdating, setDashboardUpdating] = useState(false);
  const [templateUpdating, setTemplateUpdating] = useState(false);
  const { useSendReport, useSaveCustomDashboardReport } = useReports();
  const { mutate: sendReport } = useSendReport();
  const { mutate: saveCustomDashboardReport } = useSaveCustomDashboardReport();
  const { fetchRoles } = useRoles();
  const { data: roleData, isLoading: rolesLoading } = fetchRoles();

  const handleSaveScheduleDashboardReport = (params) => {
    const { reportName, emails, recipients, delivery } = params;
    const { time, frequency, customFrequency, customStartDate } = delivery;
    const preparedFrequencyStartDate = prepareFrequencyStartDate(
      frequency,
      +frequency === ReportSendFrequency.CUSTOM ? customStartDate : null,
      time,
    );
    saveCustomDashboardReport({
      id: null,
      type: 'saved',
      name: reportName.value,
      email: emails.value,
      recipients,
      reportFreq: frequency,
      mailDeliveryFrequency: customFrequency,
      mailFrequencyStartDate: preparedFrequencyStartDate,
      frequencyDeliveryTime: time,
      sourceKey: selectedDashboardForReport && selectedDashboardForReport.uuid,
    });
    setSelectedDashboardForReport(null);
    toast.success(SuccessNotificationsLabels.REPORT_CREATED);
  };
  const handleUpdateDashboard = async (dashboard, newData) => {
    if (!dashboard || !newData) {
      return;
    }
    setDashboardUpdating(true);
    await usageStore.customDbSubStore.updateDashboard(dashboard, newData);
    setDashboardUpdating(false);
    setSelectedDashboardForEdit(null);
  };
  const handleUpdateTemplate = async (template, newData) => {
    if (!template || !newData) {
      return;
    }
    setTemplateUpdating(true);
    await usageStore.customDbSubStore.customDashboardModel.updateDashboardTemplate(template, newData);
    setTemplateUpdating(false);
    setSelectedDashboardForEdit(null);
  };
  const handleCloneDashboard = async (dashboard, data, clonedPanelSuffix) => {
    if (!dashboard || !data) {
      return;
    }
    setDashboardUpdating(true);
    await usageStore.customDbSubStore.cloneDashboard(
      {
        ...dashboard,
        ...data,
      },
      clonedPanelSuffix,
    );
    setDashboardUpdating(false);
    setSelectedDashboardForClone(false);
  };
  const handleCloneTemplateToDashboards = async (dashboard, data, clonedPanelSuffix) => {
    if (!dashboard || !data) {
      return;
    }
    setTemplateUpdating(true);
    await usageStore.customDbSubStore.customDashboardModel.cloneTemplateToDashboards(
      {
        ...dashboard,
        ...data,
      },
      clonedPanelSuffix,
      dashboard.accountId,
      true,
    );
    setTemplateUpdating(false);
    setTemplateSelectedForCloneToDashboards(null);
  };

  const handleMakeTemplateFromDashboard = async (dashboard) => {
    setTemplateUpdating(true);
    const panels = await usageStore.customDbSubStore.customDashboardModel.getPanelsByIds(dashboard.panelsIds);
    if (panels.some((p) => +p.accountKey !== +usersStore.currDispUserAccountKey)) {
      toast.error('Template can not contain panels from another account');
      setTemplateUpdating(false);
      return;
    }
    if (!isEmptyArray(dashboard.filters)) {
      toast.warning('The dashboard contains filters, filters aren’t kept as part of the template');
    }
    try {
      await usageStore.customDbSubStore.customDashboardModel.createTemplateFromDashboard(dashboard.uuid);
    } catch (e) {
      console.log(e);
    }
    toast.success('Template saved');
    setTemplateUpdating(false);
  };

  const handleDeleteDashboard = async (action) => {
    if (action === 'delete') {
      setDashboardUpdating(true);
      await usageStore.customDbSubStore.customDashboardModel.removeDashboard(dashboardSelectedForDelete.uuid);
    }
    setDashboardSelectedForDelete(null);
    setDashboardUpdating(false);
  };

  const handleDeleteTemplate = async (action) => {
    if (action === 'delete') {
      setTemplateUpdating(true);
      await usageStore.customDbSubStore.customDashboardModel.deleteDashboardTemplate(templateSelectedForDelete.uuid);
    }
    setTemplateSelectedForDelete(null);
    setTemplateUpdating(false);
  };

  const handleMakeDefaultDashboard = async (dashboard) => {
    await usageStore.customDbSubStore.customDashboardModel.makeDefaultDashboard(dashboard, dashboard.isDefault);
  };

  const handleOpenShareSettingsModal = (item) => {
    setShareSettingsModalOpen({
      uuid: item.uuid,
      setting: item.setting,
    });
  };

  const dbSettingsFormatter = (data) => {
    const { setting } = data.row;
    if (!setting) {
      return 'Current Role';
    }
    const { label } = AccessLevelOptions.find((l) => l.value === setting.level);
    const allRoles = roleData ? [...roleData] : [];
    if (setting.level === ACCESS_LEVELS.ROLES) {
      return (
        <Tooltip
          title={(setting.roles || []).map((id) => (allRoles.find((r) => r.uuid === id) || {}).roleName).join(', ')}
        >
          <span>{label}</span>
        </Tooltip>
      );
    }
    if (setting.level === ACCESS_LEVELS.USERS) {
      return (
        <Tooltip title={(setting.emails || []).join(', ')}>
          <span>{label}</span>
        </Tooltip>
      );
    }
    return label;
  };
  const handleOpenDbLink = (data, isTemplate) => ({
    pathname: `${isTemplate ? Routes.CUSTOM_DASHBOARD_TEMPLATE : Routes.CUSTOM_DASHBOARD}/${data.uuid}`,
  });
  const linkToDashboardFormatter = (data, isTemplate) => (
    <Link to={handleOpenDbLink(data.row, isTemplate)}>
      <Tooltip title={data.row.name}>
        <span>{data.row.name}</span>
      </Tooltip>
    </Link>
  );
  const userActionsFormatter = (data) => {
    const { getCurrentDisplayedAccountId, currentDisplayedUserKey, currDispUserAccountKey } =
      usersStore;
    const { isDefault } = data.row;
    const currDispUserCloudAccId = getCurrentDisplayedAccountId(currDispUserAccountKey);
    const isAdminUser = isRoleIdOfAdminType(roleData, usersStore.getCurrDisplayedUserRole());
    return (
      <div className={styles.menuCell}>
        {isDefault && (
          <Tooltip title="Default">
            <span style={{ lineHeight: 0 }}>
              <StarIcon />
            </span>
          </Tooltip>
        )}
        <Tooltip title="View">
          <span>
            <IconButton onClick={() => history.push(handleOpenDbLink(data.row, false))} size="large">
              <EyeIcon />
            </IconButton>
          </span>
        </Tooltip>
        <ReadOnlyDisplayWrapper category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
          <CustomDashboardActionButton
            showDelete={!data.row.notToDelete}
            dashboard={data.row}
            dashboardHandlers={{
              viewDashboard: (db) => history.push(handleOpenDbLink(db, false)),
              editDashboard: setSelectedDashboardForEdit,
              deleteDashboard: setDashboardSelectedForDelete,
              makeDefaultDashboard: handleMakeDefaultDashboard,
              shareSettingsModal: handleOpenShareSettingsModal,
              scheduleDashboardReport: setSelectedDashboardForReport,
              cloneDashboardModal: setSelectedDashboardForClone,
              templateDashboardModal: handleMakeTemplateFromDashboard,
            }}
            currDispUserCloudAccId={currDispUserCloudAccId}
            currentDisplayedUserKey={currentDisplayedUserKey}
            currDispUserAccountKey={currDispUserAccountKey}
            currDispUserAdminRole={isAdminUser}
          />
        </ReadOnlyDisplayWrapper>
      </div>
    );
  };
  const userActionsFormatterTemplate = (data) => {
    const { currentDisplayedUserKey } = usersStore;
    const isAdminUser = isRoleIdOfAdminType(roleData, usersStore.getCurrDisplayedUserRole());
    return (
      <div className={styles.menuCell}>
        <Tooltip title="View">
          <span>
            <IconButton onClick={() => history.push(handleOpenDbLink(data.row, true))} size="large">
              <EyeIcon />
            </IconButton>
          </span>
        </Tooltip>
        <ReadOnlyDisplayWrapper category={HierarchicalEntityCategory.Dashboards} action={Action.Update}>
          <CustomDashboardTemplateActionButton
            dashboard={data.row}
            dashboardHandlers={{
              editDashboardTemplate: setTemplateSelectedForEdit,
              deleteDashboardTemplate: setTemplateSelectedForDelete,
              copyTemplateToDashboards: setTemplateSelectedForCloneToDashboards,
            }}
            currentDisplayedUserKey={currentDisplayedUserKey}
            currDispUserAdminRole={isAdminUser}
          />
        </ReadOnlyDisplayWrapper>
      </div>
    );
  };

  const cspFormatter = (data) => {
    const CloudTypeIcon = cloudTypeToIcon[data.row.panelsAccounts.cloudTypeId];
    if (CloudTypeIcon && Array.isArray(data.row.panelsAccounts.accountNames)) {
      return (
        <Tooltip title={`${data.row.panelsAccounts.accountNames.join(', ')}`}>
          <span>
            <CloudTypeIcon />
          </span>
        </Tooltip>
      );
    }
    return '';
  };

  const { dashboards, dashboardTemplates, modelIsLoading, existingDashboardsNamesAndIds } =
    usageStore.customDbSubStore.customDashboardModel;
  const { customDbSubStore } = usageStore;

  if (modelIsLoading || rolesLoading) {
    return <Spinner />;
  }
  const renderDashboardTable = () => {
    if (dashboardUpdating) {
      return (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      );
    }
    if (isEmptyArray(dashboards)) {
      return (
        <div className={styles.emptyBlock}>
          <EmptyListIcon />
          <p>No dashboards</p>
        </div>
      );
    }
    return (
      <Grid rows={filterBySearch(dashboards, searchStr)} columns={Constants.CUSTOM_DASHBOARD_TABLE_COLUMNS}>
        <SortingState
          defaultSorting={[{ columnName: 'creationDate', direction: 'desc' }]}
          columnExtensions={[{ columnName: 'csp', sortingEnabled: false }]}
        />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedSorting />
        <IntegratedPaging />
        <DataTypeProvider for={['name']} formatterComponent={(data) => linkToDashboardFormatter(data, false)} />
        <DataTypeProvider for={['csp']} formatterComponent={cspFormatter} />
        <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
        <DataTypeProvider
          for={['creationDate']}
          formatterComponent={(data) => standardDateFormat(data.row.creationDate)}
        />
        <DataTypeProvider for={['settings']} formatterComponent={dbSettingsFormatter} />
        <TableWrapper columnExtensions={Constants.CUSTOM_DASHBOARD_TABLE_COLUMN_EXTENSIONS} rowComponent={TableRow} />
        <TableColumnResizing defaultColumnWidths={Constants.CUSTOM_DASHBOARD_COLUMN_WIDTHS} resizingMode="nextColumn" />
        <TableHeaderRow showSortingControls />
        <PagingPanel />
      </Grid>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const renderTemplateTable = () => {
    if (templateUpdating) {
      return <Spinner />;
    }
    if (isEmptyArray(dashboardTemplates)) {
      return (
        <div className={styles.emptyBlock}>
          <EmptyListIcon />
          <p>No dashboards templates</p>
          <p>Select a dashboard and save it as a template</p>
        </div>
      );
    }
    return (
      <Grid
        rows={filterBySearch(dashboardTemplates, searchStr)}
        columns={Constants.CUSTOM_DASHBOARD_TEMPLATES_TABLE_COLUMNS}
      >
        <SortingState defaultSorting={[{ columnName: 'dbUpdateTime', direction: 'desc' }]} />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedSorting />
        <IntegratedPaging />
        <DataTypeProvider for={['name']} formatterComponent={(data) => linkToDashboardFormatter(data, true)} />
        <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatterTemplate} />
        <TableWrapper columnExtensions={Constants.CUSTOM_DASHBOARD_TABLE_COLUMN_EXTENSIONS} />
        <TableColumnResizing
          defaultColumnWidths={Constants.CUSTOM_DASHBOARD_TEMPLATES_COLUMN_WIDTHS}
          resizingMode="nextColumn"
        />
        <TableHeaderRow showSortingControls />
        <DataTypeProvider
          for={['creationDate']}
          formatterComponent={(data) => standardDateFormat(data.row.creationDate)}
        />
        <PagingPanel />
      </Grid>
    );
  };
  return (
    <div className={styles.container}>
      <Tabs defaultValue={TABS.DASHBOARDS}>
        <TabsList>
          <TabsTrigger value={TABS.DASHBOARDS} className={styles.tabsButton}>
            Dashboards ({dashboards.length})
          </TabsTrigger>
          <TabsTrigger value={TABS.TEMPLATES} className={styles.tabsButton}>
            Dashboards Templates ({dashboardTemplates.length})
          </TabsTrigger>
        </TabsList>
        <TabsContent value={TABS.DASHBOARDS}>{renderDashboardTable()}</TabsContent>
        <TabsContent value={TABS.TEMPLATES}>
          <p>Dashboard template is dedicated per cloud provider and will be available across all accounts</p>
          {renderTemplateTable()}
        </TabsContent>
      </Tabs>
      <div className="mb-5" />
      <DeleteWarningModal
        deletedItemName={dashboardSelectedForDelete && dashboardSelectedForDelete.name}
        isOpen={dashboardSelectedForDelete}
        handleDelete={handleDeleteDashboard}
      />
      <DeleteWarningModal
        deletedItemName={templateSelectedForDelete && templateSelectedForDelete.name}
        isOpen={templateSelectedForDelete}
        handleDelete={handleDeleteTemplate}
      />
      {shareSettingsModalOpen && (
        <SaveShareSettingsModal
          itemId={shareSettingsModalOpen.uuid}
          usersStore={usageStore.rootStore.usersStore}
          itemType={shareSettingsItemTypes.CUSTOM_DASHBOARD}
          isModalOpen={!!shareSettingsModalOpen}
          closeModal={() => setShareSettingsModalOpen(false)}
        />
      )}
      {(selectedDashboardForEdit ||
        selectedDashboardForClone ||
        templateSelectedForEdit ||
        templateSelectedForCloneToDashboards) && (
        <EditOrCloneCustomDashboardModal
          onClose={() => {
            setSelectedDashboardForEdit(null);
            setSelectedDashboardForClone(null);
            setTemplateSelectedForEdit(null);
            setTemplateSelectedForCloneToDashboards(null);
          }}
          handlers={{
            saveEdit: handleUpdateDashboard,
            saveTemplate: handleUpdateTemplate,
            clone: handleCloneDashboard,
            cloneTemplate: handleCloneTemplateToDashboards,
          }}
          dashboard={
            selectedDashboardForEdit ||
            selectedDashboardForClone ||
            templateSelectedForEdit ||
            templateSelectedForCloneToDashboards
          }
          isCloneMode={!!selectedDashboardForClone || !!templateSelectedForCloneToDashboards}
          isTemplateMode={!!templateSelectedForEdit || !!templateSelectedForCloneToDashboards}
          existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
          customDbSubStore={customDbSubStore}
        />
      )}
      {!!selectedDashboardForReport && (
        <ReportModal
          onClose={() => setSelectedDashboardForReport(null)}
          modalType={USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD}
          usageStore={usageStore}
          isOpen={!!selectedDashboardForReport}
          onSave={handleSaveScheduleDashboardReport}
          onSend={(params) => {
            const data = ReportsHelperMethods.getModalSendReportHandler(
              {
                props: { usageStore, usersStore, invoiceStore },
                state: {
                  ...{},
                  sourceKey: selectedDashboardForReport && selectedDashboardForReport.uuid,
                },
              },
              USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD,
            )(params);
            sendReport(data);
          }}
          usersStore={usersStore}
          title="Schedule Report"
        />
      )}
    </div>
  );
};

CustomDashboardsTable.propTypes = {
  searchStr: PropTypes.string,
  history: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};
CustomDashboardsTable.defaultProps = {
  searchStr: '',
};

export default withRouter(observer(CustomDashboardsTable));
