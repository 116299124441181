export default class Account {
  constructor(args) {
    this.accountKey = args.accountKey;
    this.accountId = args.accountId;
    this.accountName = args.accountName;
    this.accountTypeId = args.accountTypeId;
    this.cloudTypeId = args.cloudTypeId;
    this.divisionId = args.isKeycloakManagement ? '' : args.divisionId;
    this.divisionsIds = args.divisionsIds;
    this.dbCreationTime = args.dbCreationTime;
    this.isActive = args.isActive;
    this.firstProcessTime = args.firstProcessTime;
    this.dbCreationTime = args.dbCreationTime;
    this.lastProcessTime = this.setLastProcessTime(args.lastProcessTime);
    this.mainDivisionName = args.accountName;
    this.userKey = args.userKey;
    this.features = args.features;
    this.accountInfo = args.accountInfo || {};
    this.currencyCode = args.currencyCode;
    this.resellerAccountType = args.resellerAccountType;
    this.autoCreateCustomersPropertyName = args.autoCreateCustomersPropertyName;
    this.autoAssignLinkedAccounts = args.autoAssignLinkedAccounts;
    this.filterOutMicrosoft365 = args.filterOutMicrosoft365;
    this.isCustomerSelfManaged = args.isCustomerSelfManaged;
    this.excludedLinkedAccountMatch = args.excludedLinkedAccountMatch;
    this.isKeycloakManagement = args.isKeycloakManagement;
  }

  getAccKey = () => {
    if (this.isKeycloakManagement) {
      return this.accountId;
    }
    return this.accountKey;
  };
  getAccountTypeId = () => this.accountTypeId;
  getDivisionId = () => this.divisionId;
  getUserCurrencyCode = () => this.currencyCode;
  getMainDivisionName = () => this.mainDivisionName;
  getAccId = () => this.accountId;
  getAccTypeId = () => this.cloudTypeId;
  getAccName = () => this.accountName;
  getAccUserKey = () => this.userKey;
  getLastProcessTime = () => this.lastProcessTime;
  getFirstProcessTime = () => this.firstProcessTime;
  getFeatures = () => this.features;
  updateAccountName = (name) => {
    this.accountName = name;
  };

  setLastProcessTime = (newTime) => (newTime ? new Date(newTime) : null);
}
