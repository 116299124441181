import React from 'react';
import Input from 'shared/components/andtComponents/Input';
import TextArea from 'shared/components/andtComponents/TextArea';
import { ReactComponent as NoRoles } from '../../img/no-roles.svg';

import styles from './CreateRoleModal.module.scss';

const DetailsStep = ({ role, setRole, roleValidationErrors, saveClicked }) => (
  <div className={styles.createEditRoleContainer}>
    <div className={styles.propertiesContainer}>
      <div className={styles.propertyContainer}>
        <span>Role Name</span>
        <Input
          value={role?.identifier?.displayName}
          placeholder="Enter Name"
          onChange={(e) => {
            setRole({ ...role, identifier: { ...role.identifier, displayName: e?.target?.value } });
          }}
          isInvalid={saveClicked && roleValidationErrors.roleName}
          invalidComponent={<div>{roleValidationErrors.roleName}</div>}
        />
      </div>
      <div className={styles.propertyContainer}>
        <span>
          Description <i>(Optional)</i>
        </span>
        <TextArea
          value={role?.description}
          placeholder="Enter Description"
          onChange={(e) => {
            setRole({ ...role, description: e?.target?.value });
          }}
        />
      </div>
    </div>
    <div className={styles.noRolesImageContainer}>
      <NoRoles />
    </div>
  </div>
);

export default DetailsStep;
