import React, { useState } from 'react';
import CustomModal from 'shared/components/andtComponents/Modal';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import toast from 'shared/components/andtComponents/Toast';
import DetailsStep from './DetailsStep';
import RolesStep from './RolesStep';
import EffectivePermissionsStep from './EffectivePermissionsStep';

import styles from './CreateRoleModal.module.scss';

const CREATE_ROLE_STEPS_CONFIG = [
  {
    title: 'Details',
    titleShort: 'Details',
    component: DetailsStep,
  },
  {
    title: 'Roles',
    titleShort: 'Roles',
    component: RolesStep,
  },
  {
    title: 'Permissions',
    titleShort: 'Permissions',
    component: EffectivePermissionsStep,
  },
  // {
  //   title: 'Data Access',
  //   titleShort: 'Data Access',
  //   component: DataAccess,
  // },
  // {
  //   title: 'Users',
  //   titleShort: 'Users',
  //   component: Users,
  // },
];

const CreateRoleModal = ({ editRole, isOpen, setIsOpen }) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [saveClicked, setSaveClicked] = useState(false);
  const [role, setRole] = useState(editRole ? { ...editRole } : {});
  const [isSaving, setIsSaving] = useState(false);
  const [roleValidations, setRoleValidations] = useState({});

  const Page = CREATE_ROLE_STEPS_CONFIG[screenIndex]?.component;

  const { createRole } = useRoles();

  const saveRole = async () => {
    try {
      setIsSaving(true);
      const roleToSave = {
        identifier: { displayName: role?.identifier?.displayName },
        description: role.description,
        subRoles: role.subRoles?.map((r) => r.identifier),
        permissions: { isPartialData: true, permissions: role.permissions },
      };
      await createRole.mutateAsync({ role: roleToSave });
      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while creating a role');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const isValidTab = (indexTab) => {
    let validations = {};

    switch (indexTab) {
      case 0:
        if (!role?.identifier?.displayName) {
          validations = { ...validations, roleName: 'Role Name Is Required' };
        }
        setRoleValidations(validations);
        return !Object.keys(validations).length > 0;
      default:
        return true;
    }
  };

  const setNextPage = async () => {
    setSaveClicked(true);
    if (!isValidTab(screenIndex)) {
      return;
    }
    const nextScreenIndex = screenIndex + 1;
    if (nextScreenIndex === CREATE_ROLE_STEPS_CONFIG.length) {
      await saveRole(role);
    } else {
      setScreenIndex(nextScreenIndex);
    }
  };

  const getButtonTitle = (text, icon, isLeft) => (
    <div className={styles.buttonTitle}>
      {isLeft && <GenerateIcon iconName={icon} className={styles.buttonIcon} />}
      <div>{text}</div>
      {!isLeft && <GenerateIcon iconName={icon} className={styles.buttonIcon} />}
    </div>
  );

  return (
    <CustomModal
      className={styles}
      onClose={() => {}}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={editRole ? 'edit' : 'add'}
      onSecondPrimaryClick={() => setScreenIndex(screenIndex - 1)}
      onSave={setNextPage}
      open={isOpen}
      automationId={`${editRole ? 'edit' : 'create'}-role-modal`}
      saveDisabled={isSaving}
      saveTitle={
        screenIndex + 1 < CREATE_ROLE_STEPS_CONFIG.length
          ? getButtonTitle('Next', ICONS.chevronRight.name, false)
          : `${editRole ? 'Save Changes' : 'Create Role'}`
      }
      isSecondPrimaryError={false}
      secondPrimaryTitle={screenIndex > 0 ? getButtonTitle('Back', ICONS.chevronLeft.name, true) : null}
      secondPrimaryDisabled={screenIndex === 0}
      title={`${editRole ? 'Edit' : 'Create'} Role`}
    >
      <Stepper activeStepIndex={screenIndex} steps={CREATE_ROLE_STEPS_CONFIG} />
      {Page && (
        <Page
          role={role}
          setRole={setRole}
          saveClicked={saveClicked}
          isEditMode={!!editRole}
          roleValidationErrors={roleValidations}
        />
      )}
    </CustomModal>
  );
};

export default CreateRoleModal;
