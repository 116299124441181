import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import Button from 'shared/components/andtComponents/Button';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import { generatePileusPolicy } from 'app/containers/Onboarding/AwsOnboarding/utils/jsonTemplatesStringsGenerators';
import styles from './Policies.module.scss';

const copyToClipboard = (id) => {
  const copyText = document.getElementById(id).textContent;
  copyStrToClipboard(copyText);
};

const downloadFileToJson = async (data, fileName) => {
  const blob = new Blob([data], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AccountPoliciesPage = ({ usersStore }) => {
  const bucketName = usersStore.getCurrDisplayedAccount.accountInfo?.s3_invoice_bucket || '';

  const renderCurrentPolicy = (name, policyId, policiObj, fileName) => (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>{name}</div>
        <div className={styles.rightMenu}>
          <Button
            text="Copy"
            isTextButton
            icon={() => <GenerateIcon iconName={ICONS.clone.name} />}
            onClick={() => copyToClipboard(policyId)}
          />
          <Button
            text="Download"
            isTextButton
            icon={() => <GenerateIcon iconName={ICONS.download.name} />}
            onClick={() => downloadFileToJson(document.getElementById(policyId).textContent, fileName)}
          />
        </div>
      </div>
      <pre id={policyId} className={styles.codeBlock}>
        <code>{generatePileusPolicy(policiObj)}</code>
      </pre>
    </div>
  );

  if (usersStore.isLoading) {
    return <Spinner />;
  }
  return (
    <div className={styles.container}>
      {renderCurrentPolicy(
        'Root Account',
        'accounts__policies__root',
        { invoiceBucketName: bucketName },
        'Policy-Root-Account',
      )}
      {renderCurrentPolicy('Linked Account', 'accounts__policies__linkedacc', {}, 'Policy-Linked-Account')}
    </div>
  );
};

AccountPoliciesPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverAccountPoliciesPage = observer(AccountPoliciesPage);
export default ObserverAccountPoliciesPage;
