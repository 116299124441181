import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  addPartialRolePermissions,
  addSubRolesToRole,
  createRole,
  deletePartialRolePermissions,
  deleteRoles,
  fetchMergedPermissionsOfRoles,
  fetchPartialRolePermissions,
  fetchRoleByInternalName,
  fetchRoleDataAccess,
  fetchRolePermissions,
  fetchRoles,
  removeSubRolesFromRole,
  setRoleDataAccess,
  setRolePermissions,
} from './apiRoles';
import { handleError } from './helperFunctions';

export default function useRoles(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const rolesQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];
  const usersQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(rolesQueryKey),
    reset: () => queryClient.resetQueries(rolesQueryKey),
    fetchRoles: (params) => {
      if (!params.search) {
        // eslint-disable-next-line no-param-reassign
        delete params.search;
      }
      const key = [...rolesQueryKey, ...(params ? Object.values(params) : [])];
      return useQuery(key, () => fetchRoles(params), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      });
    },
    fetchRoleByInternalName: (roleInternalName) =>
      useQuery([...rolesQueryKey, roleInternalName], () => fetchRoleByInternalName(roleInternalName), {
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchMergedPermissionsOfRoles: ({ roleInternalNames }) =>
      useQuery([...rolesQueryKey, ...roleInternalNames], () => fetchMergedPermissionsOfRoles(roleInternalNames), {
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchRolePermissions: (roleInternalName) =>
      useQuery(
        [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS, roleInternalName],
        () => fetchRolePermissions(roleInternalName),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchPartialRolePermissions: ({ roleInternalName, category, actionId, paginationToken, search }) =>
      useQuery(
        [
          ...rolesQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          roleInternalName,
          category,
          actionId,
          paginationToken,
          search,
        ],
        () => fetchPartialRolePermissions(roleInternalName, category, actionId, paginationToken, search),
        {
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchRoleDataAccess: (roleInternalName) =>
      useQuery(
        [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, roleInternalName],
        () => fetchRoleDataAccess(roleInternalName),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    createRole: useMutation(({ role }) => createRole(role), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(rolesQueryKey);
        await queryClient.invalidateQueries(usersQueryKey);
      },
      onError: handleError,
    }),
    deleteRoles: useMutation(({ roleInternalNames }) => deleteRoles(roleInternalNames), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(rolesQueryKey);
        await queryClient.invalidateQueries(usersQueryKey);
      },
      onError: handleError,
    }),
    addSubRoles: useMutation(
      ({ roleInternalName, subRolesInternalNames }) => addSubRolesToRole(roleInternalName, subRolesInternalNames),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([...rolesQueryKey, variables.roleInternalName]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    removeSubRoles: useMutation(
      ({ roleInternalName, subRolesInternalNames }) => removeSubRolesFromRole(roleInternalName, subRolesInternalNames),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([...rolesQueryKey, variables.roleInternalName]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    setRolePermissions: useMutation(
      ({ roleInternalName, roleCategoryPermissions }) => setRolePermissions(roleInternalName, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([...rolesQueryKey, variables.roleInternalName]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    addPartialRolePermissions: useMutation(
      ({ roleInternalName, category, action, roleCategoryPermissions }) =>
        addPartialRolePermissions(roleInternalName, category, action, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            variables.roleInternalName,
            variables.category,
            variables.action,
          ]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    deletePartialRolePermissions: useMutation(
      ({ roleInternalName, category, action, roleCategoryPermissions }) =>
        deletePartialRolePermissions(roleInternalName, category, action, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            variables.roleInternalName,
            variables.category,
            variables.action,
          ]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    setRoleDataAccess: useMutation(
      ({ roleInternalName, roleDataAccess }) => setRoleDataAccess(roleInternalName, roleDataAccess),
      {
        onSuccess: (_, variables) => queryClient.invalidateQueries([...rolesQueryKey, variables.roleInternalName]),
        onError: handleError,
      },
    ),
  };
}
