import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import { Grid, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { Action, HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Button from 'shared/components/andtComponents/Button';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import LinkedAccountsTable from './LinkedAccountsTable';
import AddLinkedAccountsModal from './AddLinkedAccountsModal';
import styles from './DivisionsTable.module.scss';

const propTypes = {
  divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandedRowIds: PropTypes.array.isRequired,
  handleChangeToCustomerView: PropTypes.func.isRequired,
  handleAddNewLinkedAccs: PropTypes.func.isRequired,
  openEditDivisionModal: PropTypes.func.isRequired,
  handleDeleteDivisionClicked: PropTypes.func.isRequired,
  handleRemoveLinkedAcc: PropTypes.func.isRequired,
  setExpandedRowIds: PropTypes.func.isRequired,
};
const columns = [
  {
    name: 'name',
    title: '',
    getCellValue: (row) => (
      <div className={styles.nameCell}>
        <span className="me-2">{row.divisionNameDisplay || row.divisionName}</span>
        {!!row.divisionCode && (
          <Tooltip title="Cost center code">
            <span className={`me-2 ${styles.divisionCodeText}`}>{row.divisionCode}</span>
          </Tooltip>
        )}
        <Tooltip title="#linkedaccounts" placement="top">
          <span className={styles.linkedAccountsCount}>{`(${row.linkedAccounts.length})`}</span>
        </Tooltip>
      </div>
    ),
  },
  { name: 'actions', title: '' },
];
const DivisionsNewTable = ({
  divisions,
  handleChangeToCustomerView,
  handleAddNewLinkedAccs,
  openEditDivisionModal,
  handleDeleteDivisionClicked,
  handleRemoveLinkedAcc,
  expandedRowIds,
  setExpandedRowIds,
}) => {
  const { usersStore } = useRootStore();
  const { availbleDivisionLinkedAccounts } = usersStore.usersModel;
  const [divisionToAddLinkedAccount, setDivisionToAddLinkedAccount] = useState(null);
  const renderTableRow = ({ row, children, ...restProps }) => (
    <NewTableRow spacerHeight={16} expandedRowIds={expandedRowIds} {...restProps}>
      {children}
    </NewTableRow>
  );
  const renderRowDetail = ({ row: division }) => (
    <NewTableRowDetail>
      <LinkedAccountsTable division={division} removeLinkedAccounts={handleRemoveLinkedAcc} />
    </NewTableRowDetail>
  );
  const { NewTableRow, NewTableRowDetail, NewTableWrapper } = useTable();
  return (
    <NewTableWrapper className={`${styles.tableWrapper}`}>
      <Grid rows={divisions} columns={columns}>
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider
          for={['actions']}
          formatterComponent={({ row: { divisionId, divisionName, parentAccountKey, ...rest } }) => (
            <div className={styles.buttonsContainer}>
              <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.ResellerCustomers} action={Action.Update}>
                <div className={styles.addLinkedAccountButton}>
                  <Tooltip title="Add linked accounts" placement="left">
                    <div>
                      <Button
                        iconSize={20}
                        icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} />}
                        onClick={() => setDivisionToAddLinkedAccount({ divisionId, divisionName, ...rest })}
                      />
                    </div>
                  </Tooltip>
                  <AddLinkedAccountsModal
                    isOpen={divisionToAddLinkedAccount?.divisionId === divisionId}
                    onClose={() => setDivisionToAddLinkedAccount(null)}
                    linkedAccounts={availbleDivisionLinkedAccounts}
                    addLinkedAccounts={async (e, selectedLinkedAccounts) =>
                      handleAddNewLinkedAccs(e, divisionToAddLinkedAccount, selectedLinkedAccounts)
                    }
                  />
                </div>
              </ReadOnlyDisplayWrapper>
              <div className={styles.magnifierButton}>
                <Tooltip title="Open the cost center scope" placement="left">
                  <div>
                    <Button
                      icon={() => <GenerateIcon iconName={ICONS.magnifyingGlassDollar.name} />}
                      iconSize={20}
                      isTextButton
                      onClick={(e) => handleChangeToCustomerView(e, divisionId, divisionName, parentAccountKey)}
                    />
                  </div>
                </Tooltip>
              </div>

              <ButtonDropdown
                text=""
                isTextButton
                icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.menuIcon} />}
                automationId="useractions"
              >
                <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.CostCenters} action={Action.Update}>
                  <Button
                    onClick={() => openEditDivisionModal({ divisionId, divisionName, parentAccountKey, ...rest })}
                    id="edit"
                    text="Edit Cost Center"
                    automationId="edit-cost-center-button"
                    isTextButton
                    className={{ buttonText: styles.buttonText, icon: styles.buttonIcon }}
                    icon={() => <GenerateIcon iconName={ICONS.penToSquare.name} />}
                  />
                </ReadOnlyDisplayWrapper>
                <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.CostCenters} action={Action.Update}>
                  <Button
                    onClick={(e) => {
                      handleDeleteDivisionClicked(e, { divisionId, divisionName, parentAccountKey, ...rest });
                    }}
                    id="delete"
                    automationId="delete-cost-center-button"
                    text="Delete Cost Center"
                    isTextButton
                    className={{ buttonText: styles.buttonText, icon: styles.buttonIcon }}
                    icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
                  />
                </ReadOnlyDisplayWrapper>
              </ButtonDropdown>
            </div>
          )}
        />
        <TableWrapper rowComponent={renderTableRow} />
        <TableRowDetail contentComponent={renderRowDetail} />
      </Grid>
    </NewTableWrapper>
  );
};

DivisionsNewTable.propTypes = propTypes;
export default observer(DivisionsNewTable);
