import React from 'react';
import { ReactComponent as SlackIcon } from 'shared/img/icons/slackIcon.svg';
import { ReactComponent as JiraIcon } from 'shared/img/icons/jira.svg';
import { ReactComponent as MSTeamsIcon } from 'shared/img/icons/ms-teams.svg';

export const titleByChannelType = {
  SLACK: 'Slack',
  JIRA_CLOUD: 'Jira Cloud',
  JIRA_DATACENTER: 'Jira Data Center',
  MS_TEAMS: 'Microsoft Teams',
};

export const iconByChannelType = {
  SLACK: React.createElement(SlackIcon),
  JIRA_CLOUD: React.createElement(JiraIcon),
  JIRA_DATACENTER: React.createElement(JiraIcon),
  MS_TEAMS: React.createElement(MSTeamsIcon),
};

export const shouldIncludeRecipient = (searchText, recipient, channel) =>
  recipient?.recipientData.name?.toLowerCase().includes(searchText.toLowerCase()) ||
  channel.name.toLowerCase().includes(searchText.toLowerCase()) ||
  titleByChannelType[channel.channelType]?.toLowerCase().includes(searchText.toLowerCase());

export const groupedChannelTypes = ['MS_TEAMS'];
