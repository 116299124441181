import React, { useEffect, useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { PAGINATION_DIRECTION } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';

import styles from './AddSubRolesModal.module.scss';

const AddSubRolesModal = ({ isOpen, setIsOpen, role }) => {
  const [displayedSubRoles, setDisplayedSubRoles] = useState([]);
  const [localRole, setLocalRole] = useState({ ...role, subRole: [] });
  const [paginationToken, setPaginationToken] = useState(null);
  const [saveClicked] = useState(false);
  const [search, setSearch] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { fetchRoles, addSubRoles } = useRoles(!isOpen);
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  useEffect(() => {
    if (data?.roles && !isLoading) {
      const tmp = data.roles.filter(
        (r) =>
          !role.subRoles.find((subRole) => subRole.id === r.identifier.id) && r.identifier.id !== role.identifier.id,
      );
      setDisplayedSubRoles(tmp || []);
    }
  }, [data, isLoading, role, isOpen]);

  useEffect(() => {
    if (displayedSubRoles) {
      setSelectedRoles(localRole?.subRoles || []);
    }
  }, [displayedSubRoles]);

  useEffect(() => {
    if (selectedRoles?.length) {
      setLocalRole((prev) => ({ ...prev, subRoles: [...selectedRoles] }));
    } else {
      setLocalRole((prev) => ({ ...prev, subRoles: [] }));
    }
  }, [selectedRoles]);

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    setSelectedRoles((prev) => {
      const newSelectedRoles = prev.filter((role) => !removedRoles.find((r) => r.identifier.id === role.identifier.id));
      return Array.from(
        new Map([...newSelectedRoles, ...addedRoles].map((item) => [item.identifier.id, item])).values(),
      );
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    await addSubRoles.mutateAsync({
      roleInternalName: role?.identifier.internalName,
      subRolesInternalNames: localRole?.subRoles?.map((r) => r.identifier.internalName),
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="assign-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title="Add Roles"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <AddRoles
          roles={displayedSubRoles}
          search={search}
          setSearch={setSearch}
          pageChanged={pageChanged}
          selectedRoles={selectedRoles}
          onRolesAddedRemoved={handleRolesAddedRemoved}
        />
      )}
    </CustomModal>
  );
};

export default AddSubRolesModal;
