import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { MoreVertical } from 'react-feather';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { Link } from 'react-router-dom';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Routes } from 'shared/constants/routes';
import { AMORTIZE_COST_TYPES } from 'usage/constants/costAndUsageConstants';
import { usePermissions } from 'app/contexts/PermissionsContext';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import moment from 'moment';
import classes from './budgets.module.scss';

const getFilterFields = (filters) => {
  const filterFields = {};
  Object.keys(filters).forEach((k) => {
    filterFields[k] = 1;
  });
  return JSON.stringify(filterFields);
};

const getFiltersParams = (filters, oldFilters, fieldToFieldDistincValuesMap) => {
  const customFilters = {};
  Object.keys(filters).forEach((key) => {
    if (key === 'linkedaccid' && Object.keys(oldFilters)?.find((f) => f === 'linkedaccname')) {
      const values = [];
      filters[key].forEach((laValue) => {
        const b = fieldToFieldDistincValuesMap
          .get('linkedaccid')
          ?.find((v) => v === laValue || Object.keys(v).find((key) => key !== 'accountId' && v[key] === laValue));
        values.push(b ? b.linkedAccountId : laValue);
      });
      customFilters[key] = values;
    } else {
      customFilters[key] = filters[key];
    }
  });
  return customFilters;
};
const getCueSearchParams = (row, fieldToFieldDistincValuesMap) => {
  const filterParams = new URLSearchParams();
  const { includeFilters, excludeFilters, likeFilters, filters, isPpApplied } = row;

  const { startDate, endDate } = buildStartAndEndDate(null, null, true);
  const budgetStart =
    row.budgetType === 'expiringFixed' ? moment(row.startDate).startOf('month').format('YYYY-MM-DD') : null;
  // support old  budgets filter with link account name under link account id value
  const customIncludesFilters = getFiltersParams(includeFilters, filters.include, fieldToFieldDistincValuesMap);
  const customExcludeFilters = getFiltersParams(excludeFilters, filters.exclude, fieldToFieldDistincValuesMap);
  const customLikeFilters = getFiltersParams(likeFilters || {}, filters.like || {}, fieldToFieldDistincValuesMap);

  filterParams.set('currCostType', JSON.stringify([]));
  filterParams.set(
    'fieldToFilterdValuesMap',
    JSON.stringify({ ...customIncludesFilters, ...customExcludeFilters, ...customLikeFilters }),
  );
  filterParams.set('excludedFiltersStatusMap', getFilterFields(customExcludeFilters));
  filterParams.set('likeFiltersStatus', getFilterFields(customLikeFilters));
  filterParams.set('startDate', budgetStart || startDate);
  filterParams.set('endDate', endDate);
  filterParams.set('currPeriodGranLevel', 'day');
  filterParams.set('currentGroupBy', 'none');
  filterParams.set('filterBarGroupBy', 'none');
  filterParams.set('isCumulative', true);
  filterParams.set('isPpApplied', isPpApplied);
  if (row.costType === AMORTIZE_COST_TYPES.AMORTIZED) {
    filterParams.set('isAmortize', true);
    filterParams.set('isShowAmortizeCost', true);
  } else if (row.costType === 'net_amortized') {
    filterParams.set('isNetAmortize', true);
  }
  return `?${filterParams.toString()}`;
};
const BudgetActions = ({ row, onDuplicate, onDelete, onEdit, fieldToFieldDistincValuesMap }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleMenu = (e, closeMenu = false) => {
    e.stopPropagation();
    setAnchorEl(closeMenu ? null : e.currentTarget);
  };
  const { isHasPermission } = usePermissions();
  const updateAllowed = isHasPermission(HierarchicalEntityCategory.Budgets, Action.Update);
  return (
    <div className={classes.actionWrapper} onClick={(e) => e.stopPropagation()}>
      <LinkIconButton automationId="exploreBudget">
        <Link
          key={`${row?.budgetName}-`}
          to={{
            pathname: `${Routes.COST_USAGE_EXPLORER}`,
            search: getCueSearchParams(row, fieldToFieldDistincValuesMap),
            backToPage: { backToPage: Routes.BUDGET },
          }}
        />
        <Tooltip
          placement="top"
          title="Investigate using cost & usage explorer,
           presenting accumulative budget cost from the beginning of the month"
          arrow
        >
          <div className={classes.icon} automation-id="Investigate-cue">
            <GenerateIcon iconName={ICONS.magnifyingGlassChart.name} />
          </div>
        </Tooltip>
      </LinkIconButton>
      <IconButton
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={(e) => toggleMenu(e)}
        disabled={!updateAllowed}
        className={!updateAllowed ? classes.disabled : ''}
        automation-id="budget-actions"
        size="large"
      >
        <MoreVertical size={22} color="black" />
      </IconButton>
      <Menu
        classes={{ list: classes.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        <MenuItem classes={{ root: classes.menuItem }} onClick={() => onEdit(row)}>
          <div>
            <GenerateIcon iconName={ICONS.pencil.name} />
            <span className={classes.menuItem}>Edit</span>
          </div>
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={() => onDuplicate(row)}>
          <div>
            <GenerateIcon iconName={ICONS.clone.name} />
            <span className={classes.menuItem}>Duplicate</span>
          </div>
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={() => onDelete(row)}>
          <div>
            <GenerateIcon iconName={ICONS.deleteRegular.name} />
            <span className={classes.menuItem}>Delete</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

BudgetActions.propTypes = {
  row: PropTypes.object.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
};

export default BudgetActions;
