import React from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { ReactComponent as TestIcon } from 'shared/img/icons/minimal-test.svg';
import { shouldIncludeRecipient } from './channelsHelpers';
import { ReactComponent as RecipientsEmptyState } from './assets/recipients-empty-state.svg';
import styles from './channels.module.scss';

const EmptyRecipients = () => (
  <div className={styles.emptyRecipients}>
    <RecipientsEmptyState />
    <h4>No Channels connected yet</h4>
  </div>
);

const TestRecipientButton = ({ channelId, id }) => {
  const { testRecipients } = useChannels();
  const { mutateAsync: handleTest, isLoading: isTestingRecipient } = testRecipients();
  return (
    <Button
      onClick={() => handleTest({ channelId, recipients: [id] })}
      isTextButton
      isLoading={isTestingRecipient}
      overrideStyles={{ padding: 0 }}
      icon={() => <TestIcon />}
    />
  );
};

const DeleteRecipientButton = ({ id }) => {
  const { deleteRecipient } = useChannels();
  const { mutateAsync: handleDelete, isLoading: isDeletingRecipient } = deleteRecipient();
  return (
    <Button
      onClick={() => handleDelete(id)}
      isTextButton
      isLoading={isDeletingRecipient}
      overrideStyles={{ padding: 0 }}
      icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
    />
  );
};

const DeleteChannelButton = ({ id }) => {
  const { deleteChannel } = useChannels();
  const { mutateAsync: handleDelete, isLoading: isDeletingChannel } = deleteChannel();
  return (
    <Button
      onClick={() => handleDelete(id)}
      isTextButton
      isLoading={isDeletingChannel}
      overrideStyles={{ padding: 0 }}
      icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
    />
  );
};

export default function ChannelDetails({ row, searchText }) {
  const { NewTableRowDetail } = useTable();

  const defaultColumns = [
    // { name: 'linkedConsumers', title: 'Linked Entities', getCellValue: (row) => row.consumerIds?.length },
    { name: 'createdBy', title: 'Created by' },
    { name: 'createdDate', title: 'Created at' },
    {
      name: 'test',
      title: ' ',
      getCellValue: (row) => <TestRecipientButton channelId={row.channelId} id={row.id} />,
    },
    {
      name: 'delete',
      title: ' ',
      getCellValue: (row) => <DeleteRecipientButton id={row.id} />,
    },
  ];

  const columnsByChannelType = (channelType) =>
    ({
      SLACK: [{ name: 'name', title: 'Name', getCellValue: (row) => row.recipientData.name }, ...defaultColumns],
      JIRA_CLOUD: [
        { name: 'anodotName', title: 'Name' },
        { name: 'userName', title: 'User', getCellValue: () => row.connectionData?.userName },
        { name: 'name', title: 'Project', getCellValue: (row) => row.recipientData.name },
        { name: 'type', title: 'Type', getCellValue: (row) => row.recipientData.jiraIssueInfo.name },
        ...defaultColumns,
      ],
      JIRA_DATACENTER: [
        { name: 'anodotName', title: 'Name' },
        { name: 'name', title: 'Project', getCellValue: (row) => row.recipientData.name },
        { name: 'type', title: 'Type', getCellValue: (row) => row.recipientData.jiraIssueInfo.name },
        ...defaultColumns,
      ],
      MS_TEAMS: [
        { name: 'name', title: 'Name', getCellValue: (row) => row.name },
        { name: 'createdBy', title: 'Created by' },
        { name: 'createdDate', title: 'Created at' },
        {
          name: 'test',
          title: ' ',
          getCellValue: (row) => <TestRecipientButton channelId={row.channelId} id={row.id} />,
        },
        {
          name: 'delete',
          title: ' ',
          getCellValue: (row) => <DeleteChannelButton id={row.channelId} />,
        },
      ],
    }[channelType] || defaultColumns);

  const columnExtensions = [
    { columnName: 'type', width: 100 },
    // { columnName: 'linkedConsumers', width: 110 },
    { columnName: 'createdBy', width: 190 },
    { columnName: 'createdDate', width: 100 },
    { columnName: 'test', width: 80, align: 'center' },
    { columnName: 'delete', width: 60, align: 'center' },
  ];

  const filteredRows = row.groupedChannels
    ? row.groupedChannels.filter((channel) => shouldIncludeRecipient(searchText, null, channel))
    : row.recipients.filter((recipient) => shouldIncludeRecipient(searchText, recipient, row));

  return (
    <NewTableRowDetail row={row} className="sub-table">
      <Grid
        rows={filteredRows}
        columns={
          filteredRows.length ? columnsByChannelType(row.channelType || row.groupedChannels[0]?.channelType) : []
        }
      >
        <Table noDataRowComponent={EmptyRecipients} columnExtensions={columnExtensions} />
        {!!filteredRows.length && <TableHeaderRow />}
        <DataTypeProvider
          for={['createdDate']}
          formatterComponent={({ value }) => <span>{moment(value).format('MMM DD YYYY')}</span>}
        />
      </Grid>
    </NewTableRowDetail>
  );
}
ChannelDetails.propTypes = {
  row: PropTypes.array.isRequired,
};
