import React, { useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import If from 'shared/components/SimpleIf';
import RecOptionsFactory from 'recommendations/helpers/factories/RecOptionsFactory';
import RecContentFactory from 'recommendations/helpers/factories/RecContentFactory';
import RecStatisticsFactory from 'recommendations/helpers/factories/RecStatisticsFactory';
import { HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import RecDescriptionFactory from 'recommendations/helpers/factories/RecDescriptionFactory';
import RecActionFactory from 'recommendations/helpers/factories/RecActionFactory';
import { ContentPageTypes, RecommFieldsAndFiltersValues } from 'recommendations/constants/recommendationsConstants';
import DismissRecButton from 'recommendations/shared/components/DismissRecButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { RecommendationBuilderContextProvider } from 'recommendations/shared/components/RecommendationBuilderContext';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';

const recommendationDetails = ({ Options, Content, Description, Statistics, Action }) => {
  const RecommendationDetails = ({ rec, recHandlers, afterActionHandler, cloudTypeId }) => (
    <div style={{ maxWidth: 'calc(100vw - 240px)', paddingLeft: '15px' }}>
      <Container className="ml-0">
        {Options ? (
          <>
            <Options recommendation={rec} />
          </>
        ) : (
          <div className="mt-4" />
        )}
        {rec?.attentionComment ? (
          <div
            style={{
              maxWidth: 570,
              fontSize: 14,
              color: '#D10F37',
              display: 'flex',
              gap: 15,
              paddingBottom: 45,
            }}
          >
            <GenerateIcon style={{ paddingTop: 4 }} iconName={ICONS.circleExclamation.name} />
            <span>{rec.attentionComment}</span>
          </div>
        ) : null}
        <Row style={{ whiteSpace: 'pre' }}>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className="fs-exclude">
              <p className="project-summary__statistic-title">Details</p>
              <br />
              <Content rec={rec} contentPageType={ContentPageTypes.RECS_GENERAL} />
              {Description && (
                <>
                  <Description rec={rec} />
                </>
              )}
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            {Statistics && (
              <>
                <Statistics rec={rec} />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {Action && (
              <>
                <hr />
                <Action recHandler={recHandlers.recCompletedHandler} rec={rec} />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <div className="project-summary fs-exclude" style={{ whiteSpace: 'pre', paddingLeft: '15px' }}>
            <If cond={rec.status === RecommFieldsAndFiltersValues.OPEN}>
              <hr />
              <ReadOnlyDisplayWrapper
                isHide={false}
                category={HierarchicalEntityCategory.Commitments}
                action={Action.Update}
              >
                <Row>
                  <Col xs="auto" md="auto" lg="auto" xl="auto">
                    <Button
                      onClick={() => {
                        recHandlers.recCompletedHandler(rec.key);
                        afterActionHandler();
                      }}
                      color="primary"
                      size="sm"
                    >
                      Done
                    </Button>
                  </Col>
                  <Col xs="auto" md="auto" lg="auto" xl="auto">
                    <If cond={rec.status === RecommFieldsAndFiltersValues.OPEN}>
                      <DismissRecButton
                        rec={rec}
                        recExcludeHandler={recHandlers.recExcludeHandler}
                        afterActionHandler={afterActionHandler}
                        cloudTypeId={cloudTypeId}
                      />
                      <br />
                    </If>
                  </Col>
                </Row>
              </ReadOnlyDisplayWrapper>
            </If>
          </div>
        </Row>
      </Container>
    </div>
  );

  RecommendationDetails.propTypes = {
    rec: PropTypes.object.isRequired,
    recHandlers: PropTypes.object.isRequired,
    afterActionHandler: PropTypes.func.isRequired,
    cloudTypeId: PropTypes.number.isRequired,
  };

  return RecommendationDetails;
};

const RecommendationDetailsBuilder = ({ recomm, recHandlers, afterActionHandler, cloudTypeId }) => {
  if (!recomm) {
    return null;
  }

  const [selectedOptionNumber, setSelectedOptionNumber] = useState(0);

  const isRecommendationWithAlternatives = !!(Array.isArray(recomm?.alternatives) && recomm.alternatives.length);

  const recommendation = useMemo(
    () => (isRecommendationWithAlternatives ? { ...recomm, selectedOption: selectedOptionNumber } : recomm),
    [isRecommendationWithAlternatives, selectedOptionNumber],
  );

  const onOptionSelected = (optionNumber) => {
    if (isRecommendationWithAlternatives) {
      setSelectedOptionNumber(optionNumber);
    }
  };

  const Options = RecOptionsFactory.create(recommendation);
  const Content = RecContentFactory.create(recommendation);
  const Statistics = RecStatisticsFactory.create(recommendation);
  const Description = RecDescriptionFactory.create(recommendation);
  const Action = RecActionFactory.create(recommendation);
  const RecommendationDetails = recommendationDetails({
    Options,
    Content,
    Description,
    Statistics,
    Action,
  });
  return (
    <RecommendationBuilderContextProvider selectedOption={selectedOptionNumber} onOptionSelected={onOptionSelected}>
      <RecommendationDetails
        rec={recommendation}
        recHandlers={recHandlers}
        afterActionHandler={afterActionHandler}
        cloudTypeId={cloudTypeId}
      />
    </RecommendationBuilderContextProvider>
  );
};

RecommendationDetailsBuilder.propTypes = {
  recomm: PropTypes.object.isRequired,
  recHandlers: PropTypes.object.isRequired,
  afterActionHandler: PropTypes.func.isRequired,
  cloudTypeId: PropTypes.number.isRequired,
};
export default RecommendationDetailsBuilder;
