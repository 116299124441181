import React, { useEffect, useMemo, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { palette } from 'shared/constants/colorsConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { maxNumSizeAbbreviation } from 'shared/utils/strUtil';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { ReactComponent as NoPotentialSavings } from 'recommendationsNew/img/savingOpportunitiesEmpty.svg';
import moment from 'moment';
import { Routes } from 'shared/constants/routes';
import useWasteReport from '../../../hooks/react-query/useWasteReport';
import { useRecommendationsNewContext } from '../../../contexts/recommendationsNewContext';
import { DEFAULT_TARGET_WASTE, FILTERS, NO_WASTE_RECOMMENDATION_TYPES, TARGET_WASTE } from '../../../consts';
import { CloudWasteChartTooltip } from './cloudWasteChartTooltip';
import { CloudWasteChartLegend } from './cloudWasteChartLegend';
import { getFiltersForWasteChart } from '../heatMapFilters/filterStates';

import classes from './cloudWaste.module.scss';

const CloudWasteChart = () => {
  const { currencySymbol } = useUserSettingsContext();
  const {
    recommendationFilters: filtersContext,
    recommendationExternalFilters: externalFiltersContext,
    isDashboardPanel,
  } = useRecommendationsNewContext();
  const { usersStore } = useRootStore();

  const [targetWastePreference, setTargetWastePreference] = useState(DEFAULT_TARGET_WASTE);
  const [displayCurrentCost, setDisplayCurrentCost] = useState(false);

  const preferencesHook = usePreferences();
  const { data: preferences } = preferencesHook.fetchPreferences(usersStore.currDispUserAccountKey);

  useEffect(() => {
    if (preferences) {
      const prefTargetWaste = preferences.find((pref) => pref.preferenceKey === TARGET_WASTE);
      setTargetWastePreference(prefTargetWaste?.preferences?.targetWaste || DEFAULT_TARGET_WASTE);
    }
  }, [preferences]);

  const filtersForWasteReport = useMemo(() => getFiltersForWasteChart(filtersContext), [filtersContext]);

  const wasteReportHook = useWasteReport();
  const { data: wasteReportData, isLoading: isWasteReportLoading } = wasteReportHook.fetchWasteReport(
    filtersForWasteReport,
    externalFiltersContext,
  );

  const getPercentageWaste = (currentCost, recommendedCost) =>
    recommendedCost !== 0 ? ((currentCost - recommendedCost) * 100) / recommendedCost : 100;

  const chartData = useMemo(() => {
    if (!wasteReportData) {
      return undefined;
    }
    const sorted = wasteReportData?.sort((a, b) => b.processing_date.localeCompare(a.processing_date)).reverse();
    const chartDataCalc = sorted?.map((item) => {
      const itemWaste = getPercentageWaste(item.current_unblended, item.recommended_unblended);
      const chartDataTmp = {
        usageDate: createDateDisplayStr(item.processing_date),
        fullDate: moment(item.processing_date).format('MMM D, YYYY'),
        currentCost: item.current_unblended,
        targetWaste:
          itemWaste > targetWastePreference
            ? item.current_unblended * (targetWastePreference / 100)
            : item.current_unblended - item.recommended_unblended,
        overWaste:
          itemWaste > targetWastePreference
            ? item.current_unblended -
              item.current_unblended * (targetWastePreference / 100) - // target waste
              item.recommended_unblended
            : 0,
      };
      chartDataTmp.targetWaste = chartDataTmp.targetWaste < 0 ? 0 : chartDataTmp.targetWaste;
      return chartDataTmp;
    });
    return chartDataCalc;
  }, [wasteReportData, targetWastePreference]);

  const maxYAxisValue = useMemo(() => {
    if (!wasteReportData) {
      return undefined;
    }
    if (displayCurrentCost) {
      return Math.max(
        ...wasteReportData?.map((item) => item.current_unblended),
        ...chartData?.map((item) => Math.max(item.overWaste, item.targetWaste)),
      );
    }
    return Math.max(...chartData?.map((item) => Math.max(item.overWaste, item.targetWaste)));
  }, [wasteReportData, displayCurrentCost]);

  const minYAxisValue = useMemo(() => {
    if (!wasteReportData) {
      return undefined;
    }
    if (displayCurrentCost) {
      return Math.min(
        ...wasteReportData?.map((item) => item.current_unblended),
        ...chartData?.map((item) => Math.min(item.overWaste, item.targetWaste)),
        0,
      );
    }
    return Math.min(...chartData?.map((item) => Math.min(item.overWaste, item.targetWaste)), 0);
  }, [wasteReportData, displayCurrentCost]);

  const doesRecommendationHasWasteData = useMemo(() => {
    const filteredTypes = filtersContext?.[FILTERS.TYPES.id]?.map((type) => type.value);
    const hasWasteData = !(
      filteredTypes?.length > 0 && filteredTypes?.every((type) => NO_WASTE_RECOMMENDATION_TYPES.includes(type))
    );
    return hasWasteData;
  }, [JSON.stringify(filtersContext)]);

  const NoWasteDataState = () => {
    const noWasteDataText = (
      <>
        <div>Daily waste is not presented for this recommendation type</div>
        <div className={classes.noPotentialSavingsSubText}>
          Choose a different recommendation type to view waste data
        </div>
      </>
    );

    const noPotentialSavingsText = (
      <>
        <div>No Potential Savings found in this view</div>
        <div className={classes.noPotentialSavingsSubText}>Try different filters</div>
      </>
    );

    return (
      <div className={classes.emptyWasteChart}>
        {!isDashboardPanel && !doesRecommendationHasWasteData ? (
          <div className={classes.noPotentialSavingsContainer}>
            <NoPotentialSavings width={130} />
            <div className={classes.noPotentialSavingsText}>{noWasteDataText}</div>
          </div>
        ) : (
          'No data'
        )}

        {isDashboardPanel && (
          <div className={classes.noDataContainer}>
            <NoPotentialSavings width={130} />
            <div className={classes.noDataText}>
              {wasteReportData?.length > 0 && !doesRecommendationHasWasteData
                ? { noWasteDataText }
                : { noPotentialSavingsText }}
            </div>
          </div>
        )}
      </div>
    );
  };

  const legendWrapperStyle = {
    bottom: '40px',
    left: '50px',
    position: 'relative',
    width: '80%',
  };

  return (
    <div className={classes.cloudWasteChartContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.leftSide}>
          <div className={classes.title}>Cloud Waste</div>
          <InfoPopover isSimple={false}>
            <div className={classes.infoIconContainer}>
              <div className={classes.infoTitleContainer}>
                <InfoIcon className={classes.infoIcon} />
                <div className={classes.infoTitle}>Cloud Waste Explanation</div>
              </div>
              <div className={classes.infoIconText}>
                <ul>
                  <li>
                    <span>Actual cost</span> - The amount paid per day for the relevant service or the total cost
                    excluding support, Marketplace, Discounts, and RI-Fee.
                  </li>
                  <li>
                    <span>Potential Savings</span> - The available saving opportunities, this figure is calculated from
                    the relevant recommendations.
                  </li>
                  <li>
                    <span>Waste percent</span> - The percentage of waste from the actual cost.
                  </li>
                  <li>
                    <span>Target waste</span> - Reasonable waste defined as {targetWastePreference}% of the &quot;Actual
                    cost&quot;.
                  </li>
                  <li>
                    <span>Over waste</span> - The waste beyond the target waste. Calculated by reducing the target waste
                    from the potential savings.
                  </li>
                  <li>
                    The waste chart takes into account all non excluded recommendations (<b>Open</b>, <b>Done</b>,&nbsp;
                    <b>Completed</b>) to track the historical waste as well as future waste.
                  </li>
                </ul>
              </div>
            </div>
          </InfoPopover>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.preferencesContainer}>
            <GenerateIcon iconName={ICONS.gearCode.name} className={classes.preferencesIcon} />
            <span>My target waste: {targetWastePreference}%</span>
            <Tooltip title={<div>{`Change your waste target in\nthe Preferences page`}</div>} placement="top" arrow>
              <a
                href={`${Routes.RECOMMENDATION_PREFERENCES_NEW}?search=waste`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkColor}
                automation-id="change-waste-target"
              >
                Change
              </a>
            </Tooltip>
          </div>
          <Tooltip
            /* eslint-disable-next-line max-len */
            title="The actual total cost or actual cost of the related service in case specific recommendation was chosen"
            placement="top"
            arrow
          >
            <div className={classes.currentCost}>
              <Checkbox
                isChecked={displayCurrentCost}
                className={classes.checkbox}
                onChange={() => setDisplayCurrentCost(!displayCurrentCost)}
              >
                <span>Display Actual cost</span>
              </Checkbox>
            </div>
          </Tooltip>
        </div>
      </div>

      {isWasteReportLoading || !wasteReportData ? (
        <Spinner />
      ) : (
        <>
          {!isWasteReportLoading && wasteReportData?.length > 0 && doesRecommendationHasWasteData ? (
            <ResponsiveContainer width="100%" height={284}>
              <ComposedChart
                width={550}
                height={260}
                data={chartData}
                margin={{ top: 15, right: 20, bottom: 5, left: 0 }}
              >
                <CartesianGrid horizontal vertical={false} stroke={palette.gray[200]} />
                <XAxis
                  axisLine={{ stroke: palette.gray[300] }}
                  dataKey="usageDate"
                  tickLine={false}
                  tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
                  type="category"
                />
                <YAxis
                  axisLine={false}
                  domain={[minYAxisValue, maxYAxisValue]}
                  tickLine={false}
                  tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
                  tickFormatter={(value) =>
                    `${currencySymbol} ${maxNumSizeAbbreviation(
                      Math.max(Math.abs(maxYAxisValue), Math.abs(minYAxisValue)),
                      value,
                    )}`
                  }
                  type="number"
                />
                <RechartTooltip content={<CloudWasteChartTooltip />} />
                <defs>
                  <linearGradient id="overWasteColor" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="15%" stopColor={palette.red[300]} />
                    <stop offset="100%" stopColor="#FFF4F6" />
                  </linearGradient>
                  <linearGradient id="targetWasteColor" x1="0" y1="0" x2="0" y2="1">
                    <stop stopColor={palette.yellow[100]} />
                    <stop offset="1" stopColor={palette.yellow[200]} />
                  </linearGradient>
                  <pattern id="diagonalLines" patternUnits="userSpaceOnUse" width="20" height="20">
                    <path d="M-1,1 l2,-2 M0,20 l20,-20 M19,21 l2,-2" stroke={palette.white[500]} strokeWidth="1" />
                  </pattern>
                </defs>
                <Area
                  dataKey="overWaste"
                  name="Over Waste"
                  stroke={palette.tomato[400]}
                  strokeWidth={2}
                  type="monotone"
                  fill="url(#overWasteColor)"
                />
                <Area
                  dataKey="overWaste"
                  name="Over Waste"
                  stroke={palette.tomato[400]}
                  strokeWidth={2}
                  type="monotone"
                  fill="url(#diagonalLines)"
                />
                <Area
                  dataKey="targetWaste"
                  name="Target Waste"
                  stroke={palette.yellow[400]}
                  strokeWidth={2}
                  type="monotone"
                  fillOpacity={1}
                  fill="url(#targetWasteColor)"
                />
                {displayCurrentCost ? (
                  <Line
                    dataKey="currentCost"
                    dot={false}
                    id="Actual Cost"
                    name="Actual Cost"
                    stroke={palette.gray[400]}
                    strokeDasharray="2 2"
                    type="monotone"
                  />
                ) : null}
                <Legend content={<CloudWasteChartLegend />} wrapperStyle={legendWrapperStyle} />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <NoWasteDataState />
          )}
        </>
      )}
    </div>
  );
};

export default CloudWasteChart;
